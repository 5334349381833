.partner-view-single-container---top-bar {
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 10pt);
	height: 50pt;
	margin-right: 10pt;
	margin-bottom: 10pt;
	padding-left: 15pt;
	gap: 10pt;
	> span {
		display: block;
		width: auto;
		height: 30pt;
		line-height: 30pt;
		font-size: 16pt;
		&:nth-of-type(1) {
			font-weight: bold;
			opacity: 0.5;
		}
		&:nth-of-type(2) {
			font-weight: bold;
			> span {
				font-weight: normal;
				opacity: 0.5;
				margin-left: 10pt;
			}
		}
	}
	> i {
		display: block;
		height: 30pt;
		line-height: 34pt;
		font-size: 12pt;
		padding: 0 10pt;
		opacity: 0.5;
	}
}

.partner-view-single-container---flexy {
	display: flex;
	justify-content: space-between;
	gap: 0pt;
	height: calc(100% - 50pt - 10pt);
	margin-right: 10pt;
	> div {
		&:nth-child(1) {
			display: flex;
			width: 175pt;
			height: 100%;
		}
		&:nth-child(2) {
			display: block;
			overflow: scroll;
			width: calc(100% - 175pt);
			height: 100%;
		}
	}
}

.table-partners---registry {
	> div {
		> div {
			&:nth-child(1) { width: 50pt; }
			&:nth-child(2) { width: 200pt; }
			&:nth-child(3) {
				width: calc(100% - 200pt - 50pt);
				font-weight: bold;
				text-transform: uppercase;
				user-select: text;
			}
		}
	}
}

.table-partners---leads {
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 120pt; }
			&:nth-child(3) { width: calc(85% - 120pt - 160pt); }
			&:nth-child(4) { width: calc(15%); }
		}
	}
}

.table-partners---activities {
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 120pt; }
			&:nth-child(3) { width: calc(15%); }
			&:nth-child(4) { width: calc(85% - 120pt); }
		}
	}
}

.table-partners---sales {
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 80pt; }
			&:nth-child(3) { width: calc(60% - 120pt); font-weight: bold; }
			&:nth-child(4) { width: 150pt; }
			&:nth-child(5) { width: 15%; }
			> img {
				display: block;
				width: 40pt;
				height: 30pt;
				background-color: var(--color-shadow-hover);
				object-fit: cover;
			}
			> .vehicle---sold {
				color: var(--color-success);
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}

.table-partners---documents {
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 200pt; }
			&:nth-child(3) { width: calc(100% - 320pt); font-weight: bold; }
			&:nth-child(4) { width: 150pt; }
			&:nth-child(5) { width: 150pt; }
		}
	}
}
