.container---table---vehicles {
	height: calc(100% - var(--topbar-optionbar-h) - 10pt);
	overflow: scroll;
	margin-right: 10pt;
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 80pt; }
			&:nth-child(3) { width: calc(100% - (120pt * 6)); }
			&:nth-child(4) { width: 100pt; text-align: left; }
			&:nth-child(5) { width: 100pt; text-align: left; }
			&:nth-child(6) { width: 100pt; text-align: right; }
			&:nth-child(7) { width: 120pt; text-align: right; }
			&:nth-child(8) { width: 120pt; }
			&:nth-child(9) { width: 200pt; }
			> img {
				display: block;
				width: 40pt;
				height: 30pt;
				background-color: var(--color-shadow-hover);
				object-fit: cover;
			}
			> .vehicle---sold {
				display: block;
				height: 30pt;
				line-height: 30pt;
				text-align: center;
				width: 100%;
				background-color: var(--color-success);
				color: var(--color-light);
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}
