.settings-overview---flexy {
	display: flex;
	justify-content: space-between;
	gap: 0pt;
	height: 100%;
	margin-right: 10pt;
	> div {
		&:nth-child(1) {
			display: flex;
			width: 175pt;
			height: 100%;
		}
		&:nth-child(2) {
			display: block;
			overflow: scroll;
			width: calc(100% - 175pt);
			height: 100%;
		}
	}
}

.settins-container---container-flex {
	display: flex !important;
	justify-content: center !important;
	width: 100%;
	> div {
		width: 700pt !important;
	}
}






