.impoter---container---flexy {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	overflow: hidden;
	top: calc(var(--topbar-h) + 5pt);
	right: 0;
	bottom: calc(var(--bottombar-h) + 10pt);
	left: 0;
	padding: 10pt;
	width: 100%;
	> div {
		&:nth-child(1) {
			display: block;
			width: 175pt !important;
			height: 100%;
		}
		&:nth-child(2) {
			display: block;
			overflow: scroll;
			width: calc(100% - 175pt);
			height: calc(100% - 65pt);
		}
	}
}

.page---top-bar---is-sidebar---vehicles-import {
	> section {
		> label {
			height: 50pt !important;
			> img {
				width: auto !important;
				height: 20pt !important;
				margin: 5pt !important;
			}
		}
	}
}

.import-from---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	> .import-from---container---logo {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 200pt;
		> img {
			display: block;
			height: 100%;
			width: 300pt;
			object-position: center;
			object-fit: contain;
		}
	}
	> h2,
	> span {
		text-align: center;
		height: auto;
		padding: 10pt 15%;
	}
	> .import-from---container---credentials {
		display: flex;
		justify-content: center;
		> div {
			width: 400pt;
			> section {
				> p {
					width: 100pt !important;
					text-align: right;
				}
				> input {
					width: calc(100% - 100pt - 10pt - 50pt);
				}
				> select {
					width: calc(100% - 100pt - 10pt);
				}
			}
			> span {
				display: block;
				width: 100%;
				height: 10pt;
			}
		}
	}
}



//																* { outline: 1px solid orange !important; }