/*

		<div className={ 'ch-toast---container ' + ('is-color-' + this._PROPS.toast_color as string) + ((this.state.GUI___toast___isDismissing === true) ? ' is-dismissing' : ' ' ) }>
			<section>
				<p className="toast---inner">
					{ this.state.toast___content }
				</p>
			</section>
			<section>
				<i className="fas fa-times" onClick={ this.dismissToastController }></i>
			</section>
		</div>

*/
			
			
.ch-toast---container {
	position: fixed;
	z-index: 99299;
	bottom: 25pt;
	left: 5pt;
	display: flex;
	width: 450pt;
	height: auto;
	padding: 15pt;
	background-color: var(--color-light);
	transition: 0.5s;
	border-left: 5pt solid var(--color-shadow);
	gap: 15pt;
	&.is-color-success {
		border-color: var(--color-greeny);
	}
	&.is-color-normal {
		border-color: var(--color-shadow);
	}
	&.is-color-danger {
		border-color: var(--color-danger);
	}
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		&:nth-child(1) {
			height: auto;
			min-height: 30pt;
			width: calc(100% - 30pt - 15pt);
			> .toast---inner {
				display: block;
				width: 100%;
				height: auto;
				line-height: 10pt;
				font-size: 10pt;
				margin: 0;
				text-align: left;
			}
		}
		&:nth-child(2) {
			width: 30pt;
			> i {
				display: block;
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
				font-size: 10pt;
				text-align: center;
				font-weight: bold;
				opacity: 0.5;
				cursor: pointer;
				transition: 0.15s;
				&:hover {
					opacity: 1;
				}
			}
		}
	}
	&.is-dismissing {
		left: -1000pt;
		opacity: 0;
	}
}