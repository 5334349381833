.resync-media---opacizer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1273462;
	display: block;
	background-color: var(--color-light);
	opacity: 0.5;
}

.resync-media---container {
	position: fixed;
	top: calc(50vh - 175pt);
	right: calc(50vw - 250pt);
	bottom: calc(50vh - 175pt);
	left: calc(50vw - 250pt);
	z-index: 1273463;
	display: block;
	padding: 25pt;
	width: calc(500pt);
	> h3 {
		display: block;
		text-align: center;
		width: 100%;
		font-weight: bold;
		font-size: 14pt;
		text-transform: uppercase;
	}











		
}