.loading---spinner---gif {
	position: relative;
	width: 100%;
	height: 200pt;
	transform: scale(1.35);
	pointer-events: none !important;
	transform: scale(0.5);
	filter: grayscale(100%);
	opacity: 0.5;
	> img {
		position: absolute;
		top: calc(50pt -  40pt);
		left: calc(50% - 40pt);
		width: 120pt;
		height: 120pt;
		pointer-events: none !important;
		transform: scale(2);
	}
}

.loading---spinner---squared {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 10pt;
	opacity: 0.5;
	> div {
		display: flex;
		justify-content: center;
		gap: 5pt;
		width: calc((20pt * 3) + 5pt + 5pt);
		> div {
			display: block;
			width: 10pt;
			height: 10pt;
			transform: translateY(-10pt);
			background-color: var(--color-dark);
			color: transparent;
			animation-name: loading___spinner____squared___anim;
			animation-duration: 1s;
			animation-direction: alternate;
			animation-iteration-count: infinite;
			&:nth-child(1) {
				animation-delay: 0s;
			}
			&:nth-child(2) {
				animation-delay: 0.35s;
			}
			&:nth-child(3) {
				animation-delay: 0.65s;
			}
		}
	}
}

@keyframes loading___spinner____squared___anim {
	0% { transform: translateY(-10pt); }
	100% { transform: translateY(10pt); }
}