.lead---activity---single {
	display: block;
	> div {
		&:nth-child(1) {
			display: flex;
			width: calc(100% + 22pt);
			margin-left: -22pt;
			> i {
				display: block;
				width: 42pt;
				height: 42pt;
				line-height: 42pt;
				text-align: center;
				background-color: var(--color-light);
				font-size: 22pt;
				color: var(--color-shadow);
			}
			> p {
				display: flex;
				margin-left: 10pt;
				width: calc(100% - 60pt);
				height: 42pt;
				line-height: 42pt;
				font-weight: bold;
				border-bottom: 1pt solid var(--color-shadow);
				> span {
					display: block;
					height: 42pt;
					line-height: 42pt;
					&:nth-child(1) {
						width: 80%;
						margin-left: 10pt;
						text-align: left;
						font-weight: bold;
						text-transform: uppercase;
					}
					&:nth-child(2) {
						width: 20%;
						margin-right: 10pt;
						text-align: right;
						opacity: 0.5;
						font-size: 10pt;
						text-transform: uppercase;
					}
				}
			}
		}
		&:nth-child(2) {
			padding-left: calc(20pt + 10pt);
			> section {
				display: flex;
				justify-content: flex-start;
				gap: 10pt;
				padding: 10pt 0;
				> span {
					display: flex;
					gap: 10pt;
					padding: 5pt;
					font-size: 10pt;
					line-height: 10pt;
					> i {
						line-height: 10pt;
						opacity: 0.5;
					}
					> span {
						line-height: 10pt;
						font-weight: bold;
						> span {
							opacity: 0.5;
						}
					}
				}
			}
			> div {
				display: flex;
				height: auto;
				gap: 10pt;
				padding: 5pt 5pt 25pt 5pt;
				font-size: 10pt;
				line-height: 10pt;
				> i {
					line-height: 10pt;
					opacity: 0.5;
				}
				> span {
					display: block;
					line-height: 10pt;
					font-weight: bold;
					height: auto;
				}
			}
		}
	}
	&.is-undone {
		opacity: 0.5;
		> div {
			&:nth-child(1) {
				> i {
					opacity: 0 !important;
				}
				> p {
					> span {
						&:nth-child(1) {
							text-decoration: line-through !important;
						}
					}
				}
			}
		}
	}
}

.lead---activity---single---note {
	display: block;
	white-space: initial !important;
	line-height: 1.5;
	user-select: text;
	a {
		font-weight: bold;
		user-select: text;
	}
}