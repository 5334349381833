.vehicle-card---container {
	position: relative;
	height: auto;
	width: 100% !important;
	flex-direction: row;
	gap: 15pt;
	> section {
		display: flex;
		flex-direction: row;
		gap: 15pt;
		padding-bottom: 15pt;
		&:nth-child(1) {
			> section {
				&:nth-child(1) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 250pt;
					height: auto;
					> img {
						display: block;
						width: 100%;
						height: 150pt;
						object-fit: cover;
						margin-bottom: 10pt;
					}
				}
				&:nth-child(2) {
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					width: calc(100% - 250pt);
					height: auto;
					> h5 {
						font-weight: bold;
						opacity: 0.5;
						text-transform: uppercase;
						font-size: 10pt;
					}
					> h3 {
						font-weight: bold;
						font-size: 14pt;
					}
					> h4 {
						font-size: 10pt;
					}
					> p {
						font-size: 10pt;
					}
					> section {
						display: block;
						margin-top: 10pt;
						> p {
							display: block;
							text-align: right;
							&:nth-child(1) {
								font-size: 10pt;
								font-weight: bold;
							}
							&:nth-child(2) {
								font-size: 10pt;
								font-weight: bold;
								opacity: 0.5;
							}
						}
					}
				}
			}
		}
		&:nth-child(2) {
			right: 10pt;
			bottom: 10pt;
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 15pt;
			padding: 0;
			margin-top: -15pt;
			> * {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 10pt;
				margin: 0;
				border: 1pt solid var(--color-shadow);
				cursor: pointer;
				> span {
					display: block;
					height: 20pt;
					line-height: 20pt;
					font-size: 10pt;
					text-transform: uppercase;
					font-weight: bold;
				}
				> i {
					display: block;
					height: 20pt;
					line-height: 20pt;
				}
				&:hover  {
					background-color: var(--color-shadow-hover);
					& + div:nth-child(2) {
						display: block;
					}
				}
			}
		}
	}
}