
.vehicle-editor---gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10pt;
	> div {
		position: relative;
		display: block;
		width: calc((100% - (10pt * 3)) / 4);
		height: 100pt;
		align-content: flex-start;
		> i {
			position: absolute;
			z-index: 1;
			display: flex;
			justify-content: center;
			display: block;
			width: 25pt;
			height: 25pt;
			line-height: 25pt;
			text-align: center;
			opacity: 0;
			background-color: var(--color-light);
			cursor: pointer;
			&.vehicle-editor---gallery---remove {
				color: var(--color-danger);
				top: 5pt;
				left: 5pt;
			}
			&.vehicle-editor---gallery---move-backward {
				bottom: 5pt;
				left: 5pt;
			}
			&.vehicle-editor---gallery---move-forward {
				right: 5pt;
				bottom: 5pt;
			}
		}
		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: black;
		}
		&:hover {
			> i {
				opacity: 1;
			}
			> img {
				opacity: 0.5;
			}
		}
	}
}