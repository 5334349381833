.container---table---partners {
	height: calc(100% - var(--topbar-optionbar-h) - 10pt) !important;
	overflow: scroll;
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 120pt; }
			&:nth-child(3) { width: calc(50% - 120pt); }
			&:nth-child(4) { width: 25%; }
			&:nth-child(5) { width: 15%; }
			&:nth-child(6) { width: 15%; }
		}
	}
}



/*

	<div className="container---table container---table---customers">
		<div>
			<div>Nome e Cognome</div>
			<div>Email</div>
			<div>Telefono</div>
		</div>
		{this.state.___customers_list.map((___c: any, ___id: number) => (
			<div>
				{(___c['customer_type'] === 'b') ? <div>{ ___c['customer_name_business'] }</div> : <div>{ ___c['customer_name'] + ' ' + ___c['customer_surname']}</div>}
				<div>{ ___c['customer_email'] }</div>
				<div>{ ___c['customer_phone'] }</div>
				<div>{ ___c['____'] }</div>
			</div>
		))}
	</div>

*/