.md-contract-preview---opacizer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1273462;
	display: block;
	background-color: var(--color-light);
	opacity: 0.5;
}

.md-contract-preview---container {
	position: fixed;
	top: 50pt;
	right: 50pt;
	bottom: 50pt;
	left: 50pt;
	z-index: 1273463;
	display: block;
	padding: 25pt;
	width: calc(100% - 100pt);
	> .print---contract-preview---print-preview {
		display: block;
		height: 100%;
		overflow-y: scroll;
		padding: 20pt calc((100% - 800pt) / 2);
		background-color: var(--color-blacky);
		height: calc(100% - 50pt);
		width: 100%;
		> section {
			display: block;
			text-align: justify;
			text-justify: inter-word;
			background-color: var(--color-light);
			padding: 50pt;
			height: auto;
		}
	}
}