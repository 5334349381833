.com-404---container {
	position: relative;
	width: 100%;
	height: 200pt;
	transform: scale(1.35);
	pointer-events: none !important;
	transform: scale(0.5);
	filter: grayscale(100%);
	opacity: 0.5;
	> img {
		position: absolute;
		top: calc(50pt -  40pt);
		left: calc(50% - 40pt);
		width: 120pt;
		height: 120pt;
		pointer-events: none !important;
		transform: scale(2);
	}
}