.container---table---leads-list {
	height: calc(100% - var(--topbar-optionbar-h) - 10pt);
	margin-right: 10pt;
	overflow: scroll;
	> div {
		> div {
			&:nth-child(1) { width: 90pt; }
			&:nth-child(2) { width: 50pt; }
			&:nth-child(3) { width: 80pt; }
			&:nth-child(4) { width: 100pt; }
			&:nth-child(5) { width: calc(60% - 170pt - 50pt); }
			&:nth-child(6) { width: calc(40% - 250pt); }
			&:nth-child(7) { width: 150pt; font-weight: bold; }
			&:nth-child(8) { width: 150pt; font-weight: bold; }
			> img {
				display: block;
				width: 40pt;
				height: 30pt;
				background-color: var(--color-shadow-hover);
				object-fit: cover;
			}
			> .is---view-status {
				display: block;
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
				text-align: center;
				cursor: pointer;
				&.is---view-status---unviewed {
					background-color: var(--color-primary);
					color: var(--color-light);
				}
				&.is---view-status---viewed {
					background-color: var(--color-shadow-hover);
				}
			}
		}
		&:not(:nth-child(1)) {
			> div {
				&:nth-child(8) {
					> span {
						display: block;
						height: 30pt;
						line-height: 30pt;
						text-align: center;
						width: 100%;
						font-weight: bold;
						text-transform: uppercase;
						&.is---leadtable-status---new {
							background-color: var(--color-success);
							color: var(--color-light);
						}
						&.is---leadtable-status---sold {
							background-color: var(--color-primary);
							color: var(--color-light);
						}
						&.is---leadtable-status---working {
							background-color: var(--color-shadow-hover);
						}
						&.is---leadtable-status---archived {
							background-color: var(--color-warning);
							color: var(--color-light);
						}
					}
				}
			}
		}
	}
}