.leads-list---container {
	> div {
		display: flex;
		gap: 10pt;
		flex-wrap: wrap;
		padding-right: 10pt !important;
		padding-top: 0 !important;
		margin-top: 0 !important;
		height: auto;
		padding-bottom: 10pt;
		> div {
			width: calc((100% - 30pt) / 4);
		}
	}
}

@media screen and (min-width: 785px) and (max-width: 1181px) {
	.leads-list---container { 
		> div {
			> div {
				width: calc((100% - 30pt) / 3);
			}
		}
	}
}

@media screen and (max-width: 784px) {
	.leads-list---container { 
		> div {
			> div {
				width: calc((100% - 30pt) / 2);
			}
		}
	}
}

//															* { outline: 1px solid orange !important; }