.calendar---container {
	display: block;
	width: 630pt !important;
	> section {
		display: block;
		width: 100%;
		&:nth-child(1) {
			display: flex;
			height: 40pt;
			margin-bottom: 20pt;
			> button {
				display: block;
				width: 50pt;
				height: 40pt;
				line-height: 30pt;
				padding: 0;
				transition: 0.15s;
				&:hover {
					transform: scale(1.25);
				}
			}
			> div {
				display: block;
				height: 40pt;
				padding: 0;
				width: 100%;
				> h6 {
					display: block;
					height: 40pt;
					line-height: 40pt;
					text-align: center;
					text-transform: uppercase;
					> span {
						&:nth-child(1) {
							font-weight: bold;
							padding-right: 10pt;
						}
					}
				}
			}
		}
		&:nth-child(2) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			gap: 7pt;
			width: calc(630pt - 20pt);
			> div {
				position: relative;
				display: block;
				width: calc((630pt - 20pt - (7pt * 6)) / 7);
				height: 75pt;
				font-weight: bold;
				text-align: center;
				padding: 0;
				border: 2.5pt dashed transparent;
				color: var(--color-blacky);
				cursor: pointer;
				border: 2pt solid var(--color-shadow-hover);
				> span {
					position: absolute;
					top: 5pt;
					right: 0;
					left: 0;
					height: 8pt;
					font-size: 6pt;
					line-height: 8pt;
					display: block;
					font-weight: bold;
					text-transform: uppercase;
				}
				> h6 {
					display: block;
					font-size: 25pt;
					height: 75pt;
					line-height: 75pt;
				}
				> section {
					position: absolute;
					display: flex;
					justify-content: center;
					gap: 2.5pt;
					right: 0;
					bottom: 2.5pt;
					left: 0;
					width: 100%;
					height: 10pt;
					transition: 0.15s;
					> i {
						display: block;
						height: 10pt;
						line-height: 10pt;
						font-size: 6pt;
						width: 10pt;
						font-weight: bold;
						color: var(--color-blacky);
						&.fa-calendar-day { color: var(--color-blacky); }								//		activity
						&.fa-envelope-open-text { color: var(--color-warning); }						//		lead
						&.fa-money-bill-wave { color: var(--color-success); }							//		sale
						&.fa-car { color: var(--color-primary); }										//		vehicle car
						&.fa-motorcycle { color: var(--color-primary); }								//		vehicle motorbike
					}
					> span {
						display: block;
						height: 10pt;
						line-height: 10pt;
						font-size: 8pt;
						width: 10pt;
						font-weight: bold;
						color: var(--color-shadow);
						opacity: 0.5;
						filter: grayscale(0.75);
						transform: scale(0.5);
						border-radius: 5pt;
						&.fa-calendar-day { background-color: var(--color-blacky); }					//		activity
						&.fa-envelope-open-text { background-color: var(--color-warning); }				//		lead
						&.fa-money-bill-wave { background-color: var(--color-success); }				//		sale
						&.fa-car { background-color: var(--color-primary); }							//		vehicle car
						&.fa-motorcycle { background-color: var(--color-primary); }						//		vehicle motorbike
					}
				}
				&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
					font-size: 12pt;
					height: 30pt;
					line-height: 26pt;
					font-weight: bold;
					text-transform: uppercase;
					background-color: var(--color-shadow-hover);
					transition: 0.15s;
					&.is-day-header---selected {
						border-bottom: 2pt solid var(--color-primary);
					}
					&:hover {
						background-color: var(--color-shadow-hover) !important;
						cursor: default !important;
					}
				}
				&.is-not-a-day {
					background-color: var(--color-shadow);
					border-color: var(--color-shadow);
					pointer-events: none;
				}
				&.day---is-selected {
					border-color:var(--color-primary);
				}
				&.day---is-today {
					background-color: var(--color-shadow-hover);
					> h6 {
						font-weight: bold !important;
					}
				}
				&:hover {
					background-color: var(--color-shadow-hover);
				}
			}
		}
	}
}

.calendar-event-list---container {
	display: flex;
	justify-content: flex-start !important;
	width: calc(100% - (630pt - 10pt)) !important;
	padding: 0;
	height: auto;
	> div {
		display: block;
		&:nth-child(1) {
			height: calc(100% - 10pt - 40pt) !important;
			overflow-y: scroll;
			> h6 {
				display: block;
				height: 40pt;
				line-height: 40pt;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 10pt;
				font-weight: bold;
				> span {
					&:nth-child(2) {
						font-weight: bold;
						padding-right: 10pt;
					}
				}
			}
			> p {
				display: block;
				text-align: center;
				padding-top: 100pt;
				opacity: 0.5;
			}
			> .events-list---container {
				display: flex;
				flex-direction: column;
			}
		}
		&:nth-child(2) {
			height: 40pt !important;
			&.event-list-viewer-toggler---container {
				display: flex !important;
				flex-direction: row !important;
				width: 100%;
				justify-content: space-evenly;
				gap: 15pt;
				> input {
					display: none !important;
					&:checked {
						+ label {
							&::after {
								content: 'SI';
								color: var(--color-success);
							}
						}
					}
				}
				> label {
					display: flex;
					width: auto;
					gap: 7.5pt;
					margin: 0;
					cursor: pointer;
					> i {
						display: block;
						height: 20pt;
						line-height: 20pt;
						&.fa-calendar-day { color: var(--color-blacky); }								//		activity
						&.fa-envelope-open-text { color: var(--color-warning); }						//		lead
						&.fa-money-bill-wave { color: var(--color-success); }							//		sale
						&.fa-car { color: var(--color-primary); }										//		vehicle car
						&.fa-motorcycle { color: var(--color-primary); }								//		vehicle motorbike
					}
					> span {
						display: block;
						font-weight: bold;
					}
					&::after {
						content: 'NO';
						color: var(--color-danger);
						font-weight: bold;
						width: 20pt;
					}
				}
			}
		}
	}
}