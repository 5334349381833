.analytics-overview---container {
	overflow: scroll;
	height: calc(100% - 45pt);
	padding: 0 50pt;
	display: flex;
	justify-content: center;

	> section {
		display: block;
		width: 900pt;
		> h4 {
			display: block;
			text-align: center;
			font-weight: bold;
			margin: 55pt 0;
		}
		> section {
			display: block;
			width: 900pt;
			height: auto;
			> canvas {
				width: 900pt !important;
				height: 225pt !important;
			}
		}
	}
}