/*
	<div className="modal-eula---container">
		<img src="/assets/logo.squared.svg"/>
		<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md" />
	</div>
*/

.modal---modal-eula---opacizer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1273462;
	display: block;
	background-color: var(--color-light);
	opacity: 0.5;
}

.modal---modal-eula---container {
	position: fixed;
	top: 20pt;
	right: 20pt;
	bottom: 20pt;
	left: 20pt;
	z-index: 1273463;
	display: block;
	padding: 25pt;
	box-shadow: none !important;
	> .modal---modal-eula---content {
		display: block;
		height: calc(100% - 50pt);
		overflow: scroll !important;
		padding: 0 15vw;
		> section {
			width: 100%;
			> img {
				width: 100%;
				height: 75pt;
			}
		}
	}
}