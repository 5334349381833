/*
	<div className="licence-expired---container">
		<img src="/assets/logo.squared.svg"/>
		<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md" />
	</div>
*/

.modal---licence-expired---container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1273463;
	display: block;
	padding: 25pt;
	box-shadow: none !important;
	> .modal---licence-expired---content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: calc(100% - 50pt);
		overflow: scroll !important;
		padding: 0 15vw;
		> section {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 30pt;
			width: 100%;
			> img {
				width: 100%;
				height: 75pt;
				margin-bottom: 20pt;
			}
			> h3 {
				display: block;
				text-align: center;
				width: 100%;
				font-weight: bold;
				font-size: 14pt;
				text-transform: uppercase;
			}
			> p {
				display: block;
				text-align: center;
				font-size: 12pt;
				> a {
					color: var(--color-primary) !important;
					font-weight: bold;
				}
			}
		}
		> .modal---licence-expired---problems {
			position: fixed;
			display: block;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1273464;
			width: 100vw;
			height: 50pt;
			text-align: center;
			> p {
				display: block;
				text-align: center;
				> span {
					font-size: 10pt;
					text-align: center;
					&:nth-child(1) {
						margin-right: 5pt;
					}
					&:nth-child(2) {
						font-weight: bold;
						color: var(--color-primary);
						cursor: pointer !important;
					}
				}
			}
		}
	}
}