.vehicle-small---container {
	display: block;
	width: 150pt;
	padding: 10pt;
	cursor: pointer;
	transition: 0.15s;
	> img {
		display: block;
		background-color: var(--color-dark);
		width: calc(150pt - 20pt);
		height: calc(100pt - 10pt);
		margin-bottom: 10pt;
		object-fit: cover;
	}
	> h3 {
		text-align: left;
		font-size: 12pt;
		font-weight: bold;
	}
	> h4 {
		text-align: left;
		font-size: 8pt;
	}
	> h5 {
		text-align: right;
		font-size: 12pt;
		font-weight: bold;
		margin-top: 10pt;
	}
	> h6 {
		text-align: right;
		font-size: 8pt;
	}
	&:hover {
		background-color: var(--color-shadow-hover);
	}
}