.no-results---container {
	position: relative;
	width: 100%;
	padding: 50pt;
	> h6 {
		display: block;
		width: 100%;
		height: auto;
		font-size: 10pt;
		line-height: 16pt !important;
		padding: 10pt;
		font-weight: bold;
		text-align: center;
		opacity: 0.5;
		text-transform: lowercase;
		letter-spacing: 0.5pt;
	}
}