.dealer---ch-connector---container {
	display: flex;
	justify-content: center;
	height: 100%;
	margin-right: 10pt;
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 800pt;
		> img {
			transform: scale(0.5);
		}
	}
}