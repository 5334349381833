.al-alert-controller---opacizer {
	position: fixed;
	z-index: 94281;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	background-color: var(--color-dark);
	opacity: 0.5;
}

.al-alert-controller---container {
	position: fixed;
	z-index: 94282;
	top: 50pt;
	left: calc((100vw - 350pt) / 2);
	display: block;
	width: 350pt;
	height: auto;
	border: 1pt solid var(--color-shadow);
	box-shadow: var(--card-shadow);
	background-color: var(--color-light);
	padding: 7.5pt;
	> .al-alert-controller---title {
		display: flex;
		justify-content: space-around;
		user-select: none !important;
		margin-bottom: 5pt;
		height: 50pt;
		> h6 {
			display: block;
			padding: 0 10pt;
			width: 100%;
			height: 40pt;
			line-height: 40pt;
			background-color: var(--color-shadow-hover);
			> i {
				opacity: 0.5;
				font-size: 12pt;
				margin-right: 10pt;
			}
			> span {
				font-size: 12pt;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
	> .al-alert-controller---message {
		display: block;
		padding: 10pt;
		> span {
			font-weight: bold;
		}
	}
}