.nav---sidemenu---toggler {
	display: none;
	&:checked {
		~ .nav---topbar {
			> section {
				> .nav---sidemenu---hamburger {
					> section {
						> span {
							&:nth-child(1) {
								display: none;
							}
							&:nth-child(2) {
								display: block;
							}
							&:nth-child(3) {
								> span {
									&:nth-child(1) { transform: translateY(7.35pt) rotate(135deg); }
									&:nth-child(2) { transform: translateX(60px); opacity: 0; }
									&:nth-child(3) { transform: translateY(-7.35pt) rotate(-135deg); }
								}
							}
						}
					}
				}
			}
		}
		~ .nav---sidemenu---opacizer { display: block; }
		~ .nav---sidemenu---opacizer---white { opacity: 0.5; }
		~ .nav---sidemenu { right: 0 !important; }
	}
}

.nav---topbar {
	position: fixed !important;
	z-index: 93283;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	width: 100vw;
	height: var(--topbar-h);
	padding: 0;
	background-color: var(--color-light);
	box-shadow: rgba(100, 100, 100, 0.2) -4pt 2pt 10pt;
	.nav---topbar---logo {
		display: block;
		padding: 5pt;
		> img {
			display: block;
			height: 100%;
			width: auto;
			object-fit: contain;
			object-position: left;
			transform: scale(0.85);
		}
	}
	> section {
		display: flex;
		gap: 10pt;
		> div,
		  a {
			display: flex;
			gap: 10pt;
			margin: 5pt;
			height: 30pt;
			line-height: 30pt;
			cursor: pointer;
			transition: 0.15s;
			border-bottom: 2pt solid transparent;
			padding: 0;
			> span {
				display: block;
				opacity: 1;
				width: auto;
				height: 30pt;
				line-height: 30pt;
				font-weight: bold;
				text-align: right;
				text-transform: uppercase;
				padding-left: 10pt;
			}
			> div {
				margin-left: -5pt;
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
				background-color: var(--color-primary);
				transform: scale(0.75);
				border-radius: 4pt;
				text-align: center;
				color: var(--color-light);
				transition: 0.15s;
			}
			> i {
				width: 30pt;
				height: 30pt;
				line-height: 30pt;
				font-size: 15pt;
				color: var(--color-primary);
				text-align: center;
				transition: 0.15s;
			}
			&.nav-top-button---active {
				border-bottom: 2pt solid var(--color-primary);
				> div {
					border-radius: 0pt !important;
					filter: grayscale(1);
					opacity: 0.5;
					transform: scale(0.5) !important;
					font-size: 18pt;
				}
				> i {
					filter: grayscale(1);
					opacity: 0.5;
					transform: scale(0.75);
				}
			}
			&:hover {
				opacity: 0.5;
			}
		}
		> span {
			display: block;
			margin: 7.5pt;
			width: 10pt;
			text-align: center;
			height: 25pt;
			line-height: 25pt;
			color: var(--color-shadow);
		}
		> .nav---sidemenu---hamburger {
			position: relative;
			display: flex;
			width: auto;
			height: auto;
			padding: 7.5pt 7.5pt 7.5pt 0pt;
			cursor: pointer;
			> section {
				display: flex;
				position: relative;
				gap: 10pt;
				cursor: pointer;
				> span {
					&:nth-child(1) {
						display: block;
						opacity: 1;
						height: 25pt;
						width: 55pt;
						line-height: 25pt;
						font-weight: bold;
						text-align: right;
						text-transform: uppercase;
					}
					&:nth-child(2) {
						display: none;
						opacity: 0.5;
						width: 55pt;
						height: 25pt;
						line-height: 25pt;
						font-weight: bold;
						text-align: right;
						text-transform: uppercase;
					}
					&:nth-child(3) {
						width: 18pt;
						height: 25pt;
						position: relative;
						> span {
							opacity: 1;
							left: 0;
							display: block;
							width: 100%;
							height: 2pt;
							color: black;
							background-color: var(--color-primary);
							position: absolute;
							transform: rotate(0deg);
							transition: 0.4s ease-in-out;
							border-radius: 1pt;
							&:nth-child(1) { top: 4pt; }
							&:nth-child(2) { top: 11pt; }
							&:nth-child(3) { top: 18pt; }
						}
					}
				}
				&:hover {
					opacity: 0.5;
					> span {
						&:nth-child(3) {
							> span {
								&:nth-child(2) {
									transition: 0.15s;
									width: 50%;
									left: 25%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.nav---sidemenu---opacizer {
	position: fixed;
	z-index: 93284;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	height: 100vh;
	width: 100vw;
	background-color: transparent !important;
	opacity: 0;
	color: transparent !important;
}

.nav---sidemenu---opacizer---white {
	position: fixed;
	z-index: 93281;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	height: 100vh;
	width: 100vw;
	background-color: var(--color-light);
	opacity: 0;
	color: transparent !important;
	pointer-events: none;
	transition: 0.15s;
}

.nav---sidemenu {
	height: calc(100% - 50pt - 25pt);
	padding-left: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: fixed;
	width: 300pt;
	background: white;
	box-shadow: rgba(100, 100, 100, 0.2) -4pt 2pt 10pt;
	z-index: 93285;
	overflow-y: auto;
	overflow-x: hidden;
	top: 50pt;
	right: -100%;
	bottom: 25pt;
	transition: 0.4s ease-in-out;
	> div {
		border-bottom: 1pt solid var(--color-shadow-dark);
		padding: 10pt 0;
		> a {
			display: flex;
			justify-content: space-between;
			gap: 10pt;
			cursor: pointer;
			> img {
				display: block;
				width: var(--topbar-h);
				height: var(--topbar-h);
				object-fit: contain;
				transform: scale(0.55);
			}
			> span {
				display: block;
				width: calc(100% - var(--topbar-h) - var(--topbar-h) - 10pt - 10pt);
				height: var(--topbar-h);
				line-height: var(--topbar-h);
				font-size: 14pt;
				font-weight: bold;
			}
			> i {
				display: block;
				width: var(--topbar-h);
				height: var(--topbar-h);
				line-height: var(--topbar-h);
				text-align: center;
				transform: rotate(90deg);
				transition: 0.15s;
			}
			&:hover {
				> span {
					font-weight: bolder;
				}
				> i {
					transform: rotate(0deg);
				}
			}
		}
		> section {
			height: auto;
			display: block;
			overflow: hidden;
			transition: 0.15s;
			> a,
			  div {
				cursor: pointer;
				display: flex;
				gap: 10pt;
				height: 30pt;
				width: 100%;
				> span {
					display: block;
					height: 30pt;
					line-height: 30pt;
					&:nth-child(1) {
						width: var(--topbar-h);
						opacity: 1;
						transition: 0.15s;
					}
					&:nth-child(2) {
						width: calc(100% - var(--topbar-h) - var(--topbar-h) - 10pt - 10pt);
						font-size: 10pt;
						transition: 0.15s;
					}
				}
				> i {
					display: block;
					height: 30pt;
					line-height: 30pt;
					text-align: center;
					width: var(--topbar-h);
					font-size: 10pt;
					opacity: 0.5;
				}
				&.nav-sidemenu-button---active {
					font-weight: bolder !important;
					opacity: 0.25 !important;
					cursor: default !important;
					> i {
						opacity: 0.5 !important;
					}
				}
				&:hover {
					> span {
						font-weight: bold;
					}
					> i {
						opacity: 1;
					}
				}
				&.nav-element---is-logout {
					color: var(--color-danger);
				}
			}
		}
	}
}