.leads---left-bar---container {
	> section {
		> div {
			&:nth-child(1) {
				width: 350pt;
				overflow-y: scroll;
				> .is-content {
					> .input---nb-dropdown {
						> div {
							&.lead---is-sold {
								border-color: var(--color-primary);
								background-color: var(--color-primary);
								color: var(--color-light);
							}
							&.lead---is-archived {
								border-color: var(--color-warning);
								background-color: var(--color-warning);
								color: var(--color-light);
							}
						}
					}
					> .leads-single---vehicle-gallery---preview {
						display: block;
						width: 100%;
						object-fit: cover;
						> section {
							position: relative;
							width: 100%;
							&:nth-child(1) {
								height: 200pt;
								> img {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
								> span {
									position: absolute;
									right: 0;
									bottom: 0;
									left: 0;
									display: block;
									background-color: var(--color-danger);
									color: var(--color-light);
									text-align: center;
									font-weight: bold;
									font-size: 12pt;
									height: 40pt;
									line-height: 40pt;
									border-top: 1pt solid var(--color-light);
								}
							}
							&:nth-child(2) {
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								gap: 10pt;
								width: 100%;
								> img {
									margin-top: 10pt;
									display: block;
									width: calc((100% - 30pt) / 4);
									height: 40pt;
									object-fit: cover;
								}
							}
						}
					}
					> .lead---interests---container {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						gap: 10pt;
						width: 100%;
						> span {
							display: block;
							width: auto;
							padding: 5pt 10pt;
							border: 1pt solid var(--color-shadow);
						}
					}
				}
			}
			&:nth-child(2) {

			}
		}
	}
}

.lead---overview---container {
	display: block;
	overflow-y: scroll;
	width: (100%) !important;
	height: calc(100% + 10pt) !important;
	padding: 10pt 10pt 0 10pt;
	margin-right: 10pt;
	> div {
		width: 100%;
		margin-bottom: 10pt;
	}
}

.lead---chosen-car---container---warning {
	display: flex;
	justify-content: space-between;
	background-color: var(--color-warning);
	color: var(--color-light);
	margin: 0 0 10pt 0;
	width: 100%;
	height: 40pt;
	padding: 5pt 10pt;
	> span {
		display: block;
		height: 30pt;
		line-height: 30pt;
		font-weight: bold;
	}
	> a {
		display: block;
		height: 30pt;
		line-height: 30pt;
		text-decoration: underline;
		font-weight: bold;
	}
	&.warning---is-mega-success {
		background-color: var(--color-primary) !important;
	}
	&.warning---is-mega-danger {
		background-color: var(--color-danger) !important;
	}
}

.lead-car-chosen---not-available {
	display: block;
	background-color: var(--color-warning);
	color: var(--color-light);
	padding: 10pt;
	> h3 {
		background-color: var(--color-warning) !important;
		color: var(--color-light);
	}
	> div {
		> section {
			> p {
				padding-bottom: 10pt;
				font-weight: bold;
			}
			> section {
				display: flex;
				justify-content: flex-end;
				> h6 {
					font-weight: bold;
					cursor: pointer;
					> span {
						text-transform: uppercase;
					}
					> i {
						margin-left: 10pt;
					}
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.lead---activity---container {
	display: block;
	width: calc(100% - 20pt);
	height: auto;
	margin-left: 20pt;
	border-left: 2pt dashed var(--color-shadow);
}

.new-activity---container {
	padding-top: 75pt;
	display: block;
	width: calc(100% - 20pt);
	height: auto;
	margin-left: 20pt;
	border-left: 2pt dashed var(--color-shadow);
	> div {
		&:nth-child(1) {
			display: flex;
			width: calc(100% + 22pt);
			margin-left: -22pt;
			> i {
				display: block;
				width: 42pt;
				height: 42pt;
				line-height: 42pt;
				text-align: center;
				background-color: var(--color-light);
				font-size: 22pt;
				color: var(--color-primary);
			}
			> span {
				display: block;
				margin-left: 10pt;
				width: calc(100% - 60pt);
				height: 42pt;
				line-height: 42pt;
				font-weight: bold;
				border-bottom: 1pt solid var(--color-shadow);
			}
		}
		&:nth-child(2) {
			padding-left: calc(20pt + 10pt);
		}
	}
}

.leads---car-matches---container {
	display: block;
	width: 100%;
	overflow: visible !important;
	> div {
		&:nth-child(2) {
			overflow: scroll !important;
			height: calc(100% - var(--topbar-optionbar-h) - 10pt) !important;
			> .container---table---lead-vehicles {
				> div {
					width: 100%;
					> div {
						&:nth-child(1) { width: 120pt; }
						&:nth-child(2) {
							width: 120pt;
							> img {
								display: block;
								width: 100pt;
								height: 60pt;
								background-color: var(--color-shadow-hover);
								object-fit: cover;
							}
						}
						&:nth-child(3) { width: calc(50% - 120pt); }
						&:nth-child(4) { width: 150pt; }
						&:nth-child(5) {
							width: 25%;
							
						}
					}
				}
			}
		}
	}
}

.contract---container---generator {
	display: block;
	overflow-y: scroll;
	margin: 0pt;
	width: calc(100% - 10pt) !important;
	height: 100% !important;
	padding: 10pt 10pt 0 10pt;
}

.contract---container {
	display: block;
	overflow-y: scroll;
	margin: 0pt;
	width: calc(100% - 10pt) !important;
	height: 100% !important;
	padding: 10pt 10pt 0 10pt;
}

.contract---container---writer {
	display: block;
	text-align: justify;
	text-justify: inter-word;
	font-size: 10pt;
	line-height: 25pt;
	padding: 15pt 30pt 15pt 60pt;
	> h3 {
		display: block;
		width: 100%;
		font-weight: bold;
		font-size: 14pt;
		margin-left: -30pt;
	}
	> h5 {
		display: block;
		width: 100%;
		margin-left: -30pt;
		font-weight: bold;
		margin-bottom: 10pt;
	}
	> span {
		display: inline;
		line-height: 25pt;
		> b {
			display: inline-block;
			border: none;
			border-bottom: 1pt solid var(--color-dark);
			width: auto !important;
			font-weight: bold;
			text-align: center;
			height: 23pt;
			line-height: 23pt;
			font-size: 10pt;
			padding-left: 15pt;
			padding-right: 15pt;
		}
	}
	> input,
	  select {
		display: inline;
		border: none;
		border-bottom: 1pt solid var(--color-dark);
		width: auto;
		font-weight: bold;
		text-align: center;
		height: 20pt;
		line-height: 20pt;
		background-color: transparent;
		padding-left: 15pt;
		padding-right: 15pt;
	}
	> .input---nb---vehicle-status---table {
		display: block;
		width: 100%;
		font-size: 10pt;
		> div {
			display: flex;
			width: 100%;
			height: 30pt;
			line-height: 30pt;
			> div {
				&:nth-child(1) {
					display: flex;
					width: 300pt;
					> span {
						height: 25pt;
						line-height: 25pt;
						&:nth-child(1) {
							display: block;
							width: 40pt;
							text-align: center;
							opacity: 0.5;
							font-weight: bold;
						}
						&:nth-child(3) {
							display: block;
							width: calc(100% - 40pt) !important;
						}
					}
					> input,
					  select {
						display: block;
						border: none;
						border-bottom: 1pt solid var(--color-dark);
						width: auto !important;
						font-weight: bold;
						text-align: center;
						width: 100% !important;
						background-color: transparent;
					}
				}
				&:nth-child(2),
				&:nth-child(3) {
					display: flex;
					width: 200pt;
					> span {
						display: block;
						width: 20pt;
						text-align: center;
					}
					> input,
					  select {
						display: block;
						border: none;
						border-bottom: 1pt solid var(--color-dark);
						width: auto !important;
						font-weight: bold;
						text-align: center;
						width: calc(100% - 20pt) !important;
						background-color: transparent;
					}
				}
			}
			&:hover {
				background-color: var(--color-shadow-hover);
			}
		}
	}
	> .input---nb---text-page---table {
		display: block;
		width: 100%;
		font-size: 10pt;
		> div {
			display: flex;
			width: 100%;
			height: 30pt;
			line-height: 30pt;
			> div {
				&:nth-child(1) {
					display: flex;
					width: calc(100% - 200pt - 200pt);
					> span {
						height: 25pt;
						line-height: 25pt;
						&:nth-child(1) {
							display: block;
							width: 40pt;
							text-align: center;
							opacity: 0.5;
							font-weight: bold;
						}
						&:nth-child(3) {
							display: block;
							width: calc(100% - 40pt) !important;
						}
					}
					> input,
					  select {
						display: block;
						border: none;
						border-bottom: 1pt solid var(--color-dark);
						width: auto !important;
						font-weight: bold;
						text-align: center;
						width: 100% !important;
						background-color: transparent;
					}
				}
				&:nth-child(2),
				&:nth-child(3) {
					display: flex;
					width: 200pt;
					> span {
						display: block;
						width: 20pt;
						text-align: center;
					}
					> input,
					  select {
						display: block;
						border: none;
						border-bottom: 1pt solid var(--color-dark);
						width: auto !important;
						font-weight: bold;
						text-align: center;
						width: calc(100% - 20pt) !important;
						background-color: transparent;
					}
				}
			}
			&:hover {
				background-color: var(--color-shadow-hover);
			}
		}
	}
}




















//												* 							{ outline: 1pt solid orange !important; }