.md-vehicle-importer---opacizer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1273462;
	display: block;
	background-color: var(--color-light);
	opacity: 0.5;
}

.md-vehicle-importer---container {
	position: fixed;
	top: calc(75pt);
	right: calc(50vw - 300pt);
	left: calc(50vw - 300pt);
	z-index: 1273463;
	display: block;
	padding: 25pt;
	width: calc(600pt);
	> h3 {
		display: block;
		text-align: center;
		width: 100%;
		font-weight: bold;
		font-size: 14pt;
		text-transform: uppercase;
	}	
}

.md-vehicle-importer---cars-container {
	height: 300pt;
	overflow: scroll;
	padding: 10pt 0;
	> label {
		display: flex;
		justify-content: flex-start;
		height: 30pt;
		background-color: var(--color-light);
		margin-bottom: 10pt;
		overflow: hidden;
		box-shadow: var(--card-shadow);
		cursor: pointer;
		> input {
			margin-right: 15pt;
			height: 30pt;
			width: 30pt;
			line-height: 30pt;
			transform: scale(0.5);
		}
		> span {
			display: block;
			height: 30pt;
			line-height: 30pt;
		}
	}
}