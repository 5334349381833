.partner-single---card---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5pt;
	width: 100%;
	height: auto;
	border: 1pt solid var(--color-shadow);
	padding: 10pt;
	cursor: pointer;
	* {
		user-select: text;
		-webkit-user-drag: none;
	}
	> h5 {
		display: block;
		font-size: 10pt;
		font-weight: bold;
		opacity: 0.5;
	}
	> h3 {
		display: block;
		font-size: 12pt;
		font-weight: bold;
	}
	> h4 {
		display: block;
		font-size: 10pt;
	}
	> h6 {
		display: block;
		font-size: 10pt;
		opacity: 0.75;
		> span {
			opacity: 0.5;
		}
	}
	&:hover {
		background-color: var(--color-shadow-hover);
	}
}