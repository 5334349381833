.results---load-more---container {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 75pt;
	padding: 40pt;
	line-height: 14pt;
	border: none !important;
	&:hover {
		background-color: unset !important;
	}
	> section {
		display: flex;
		justify-content: center;
		width: 100%;
		> span {
			display: block;
			width: auto;
			padding: 10pt 75pt;
			cursor: pointer;
			transition: 0.15s;
			font-weight: bold;
			opacity: 0.5;
			&:hover {
				opacity: 0.25;
			}
		}
	}
}