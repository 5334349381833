.settings-user---list---container {
	> section {
		margin-top: 30pt;
		border-top: 1px solid var(--color-shadow);
		> div {
			display: flex;
			justify-content: space-between;
			width: 100%;
			border-bottom: 1px solid var(--color-shadow);
			padding: 5pt 10pt;
			cursor: pointer;
			> span {
				display: flex;
				font-size: 10pt;
				height: 30pt;
				line-height: 30pt;
				transition: 0.15s;
				text-transform: uppercase;
				> img {
					display: block;
					width: 20pt;
					height: 20pt;
					object-fit: contain;
					margin: 5pt 10pt 5pt 0;
				}
				> span {
					display: block;
					&:nth-of-type(1) {
						opacity: 1;
					}
					&:nth-of-type(2) {
						margin-left: 10pt;
						opacity: 0.5;
					}
				}
			}
			> i {
				display: block;
				font-size: 10pt;
				height: 30pt;
				line-height: 30pt;
				opacity: 0.5;
				transition: 0.15s;
			}
			&:hover {
				> img {
					opacity: 0.5;
				}
				> span {
					font-weight: bold;
				}
				> i {
					opacity: 1;
				}
			}
			&.user-is-selected {
				font-weight: bold;
				> i {
					opacity: 1;
				}
			}
		}
	}
}

/*

									<div className="is-card settings-user---list---container">
										<h3 className="is-title">Lista Utenti</h3>
										<section>
											{this.state.user___list.map((___u: any) => <>
												<div onClick={() => { this.handle___user___show(___u['user_id']) }}>
													<span>{ ___u['user_name'] + ' ' + ___u['user_surname'] }</span>
													<i className="fas fa-chevron-right"></i>
												</div>
											</>)}
										</section>
									</div>

*/

.settings-user---user---container {
	
	height: 100% !important;
	width: calc(100% - 30%);
	overflow-y: scroll;

	





}

















//															* 										{ outline: 1px solid orange !important; }