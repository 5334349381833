.com-button-copier---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding: 0;
	> i {
		display: block;
		width: 30pt;
		height: 30pt;
		line-height: 30pt;
		margin: 0;
		padding: 0;
		background-color: var(--color-shadow-hover);
		color: var(--color-blacky);
		text-align: center;
		cursor: pointer;
	}
}


//															* { outline: 1pt solid orange !important; }