.eula---container {
	overflow: scroll;
	width: calc(100% - 20pt);
	height: calc(100% - 80pt);
	margin: 10pt !important;
	> section {
		> img {
			display: block;
			width: 100%;
			height: 100pt;
			margin-top: 100pt;
			margin-bottom: 50pt;
		}
	}
}