.com-bottombar---container {
	position: fixed;
	z-index: 93283;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	width: 100vw;
	height: 20pt;
	padding: 0 10pt;
	background-color: var(--color-light);
	box-shadow: rgba(100, 100, 100, 0.2) -4pt 2pt 10pt;
	> .com-bottombar---container---version---container {
		> p {
			display: flex;
			gap: 5pt;
			width: auto;
			height: 20pt;
			line-height: 20pt;
			font-size: 10pt;
			font-weight: bold;
		}
	}
	> .com-bottombar---container---support-mode-active {
		> p {
			display: flex;
			text-transform: uppercase;
			gap: 5pt;
			width: auto;
			height: 20pt;
			line-height: 20pt;
			font-size: 10pt;
			font-weight: bolder;
			color: var(--color-warning);
			letter-spacing: 0.25pt;
		}
	}
	> .com-bottombar---container---network-status---container {
		display: flex;
		gap: 5pt;
		width: auto;
		height: 20pt;
		> i {
			display: block;
			line-height: 20pt;
			font-size: 10pt;
			margin-right: 5pt;
		}
		> span {
			display: block;
			line-height: 20pt;
			font-size: 10pt;
			text-transform: uppercase;
			font-weight: bold;
		}
		&.network-status---online {
			> i {
				color: var(--color-success);
			}
		}
		&.network-status---offline {
			> i {
				color: var(--color-danger);
			}
		}
		&.network-status---checking {
			opacity: 0.5;
		}
	}
}