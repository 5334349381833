.support-all---container {
	display: flex !important;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	> section {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 15pt;
		width: 850pt;
		height: auto;
		align-items: center;
		align-content: center;
		> h2 {
			width: 100%;
			text-align: center;
			height: 100pt;
			line-height: 100pt;
			font-weight: bold;
			font-size: 20pt;
		}
		> a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10pt;
			width: 30%;
			height: 200pt;
			padding: 15pt;
			> i { 
				display: block;
				width: 100%;;
				height: 50pt;
				line-height: 50pt;
				font-size: 25pt;
				text-align: center;
			}
			> h4 {
				display: block;
				text-align: center;
				width: 100%;
				line-height: 20pt;
				font-weight: bold;
			}
			> h6 {
				display: block;
				text-align: center;
				width: 100%;
				line-height: 14pt;
				font-size: 9pt;
				font-weight: bold;
				opacity: 0.5;
			}
			&:hover {
				border: 2pt solid var(--color-primary);
			}
		}
	}
}