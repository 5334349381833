.carshub---landing-page---container {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: -1;
	width: 100vw;
	height: 100vh;
}

.carshub---landing-page---problems {
	position: fixed;
	display: block;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100vw;
	height: 50pt;
	text-align: center;
	> p {
		display: block;
		text-align: center;
		> span {
			font-size: 10pt;
			text-align: center;
			&:nth-child(1) {
				margin-right: 5pt;
			}
			&:nth-child(2) {
				font-weight: bold;
				color: var(--color-primary);
				cursor: pointer !important;
			}
		}
	}
}