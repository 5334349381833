//			* { outline: 1px solid orange !important; }


.licence-active---overview---container {
	display: flex;
	justify-content: space-between;
	height: calc(100% - var(--topbar-optionbar-h) - 10pt);
	margin-right: 10pt;
	> div:nth-child(1) {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 400pt;
		height: 100%;
		> div {
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 25pt;
			padding: 25pt;
			border-right: 1pt solid var(--color-shadow-hover);
			> img {
				display: block;
				width: 100%;
				height: 175pt;
				transform: scale(0.5);
			}
			> h2 {
				display: block;
				width: 100%;
				font-weight: bold;
				font-size: 16pt;
				text-align: center;
				text-transform: uppercase;
				> span {
					&:nth-child(1) {
						padding: 5pt;
					}
					&:nth-child(2) {
						color: var(--color-success);
					}
				}
			}
			> .container---table {
				width: 100%;
				> div {
					> div {
						&:nth-child(1) { width: 30pt; }
						&:nth-child(2) { width: calc(50% - 30pt); }
						&:nth-child(3) { width: calc(50%); font-weight: bold; text-align: right; letter-spacing: 0.25pt; text-transform: uppercase; }
					}
				}
			}
		}
	}
	> div:nth-child(2) {
		display: flex;
		justify-content: center;
		overflow: scroll;
		width: calc(100% - 400pt);
		> .container---table {
			width: calc(100% - 50pt);
			> div {
				> div {
					&:nth-child(1) {
						display: none;
					}
					&:nth-child(2) { 
						width: 75%;
						text-align: left;
						padding-top: 10pt;
						padding-bottom: 10pt;
						> p {
							display: block;
							&:nth-child(1) {
								font-size: 12pt;
								font-weight: bold;
								margin-bottom: 5pt;
							}
							&:nth-child(2) {
								font-size: 10pt;
								line-height: 15pt;
								opacity: 0.5;
							}
						}
					}
					&:nth-child(3) {
						width: 25%;
						text-align: left;
						> span {
							display: block;
							width: 100%;
							background-color: var(--color-shadow-hover);
							padding: 5pt 10pt;
							font-weight: bold;
							text-align: center;
							color: var(--color-light);
							&.is-licence-field-active {
								background-color: var(--color-success);
							}
							&.is-licence-field-available {
								background-color: var(--color-warning);
							}
							&.is-licence-field-unavaliable {
								background-color: var(--color-danger);
							}
						}
					}
				}
			}
		}
	}
}

/*


<div>
												<div>
													<p>Analytics</p>
													<p>adfjbsd nkfjkdf ladjkf nladkjg nnklsbdjk gnlksdbjk fnlskdbfjk nalfjk anll</p>
												</div>
												<div>
													{(this.state.licence___props['licence_active___analytics'] === true) ? <>
														<span className="is-licence-field-active">ATTIVO</span>
													</> : <>
														<span className="is-licence-field-active-not">ACQUISTABILE</span>
													</>}
													<span></span>
												</div>
											</div>




*/

.container---table---licences-list {
	height: calc(100% - var(--topbar-optionbar-h) - 10pt);
	margin-right: 10pt;
	overflow: scroll;
	> div {
		> div {
			&:nth-child(1) { width: 90pt; }
			&:nth-child(2) { width: 80pt; }
			&:nth-child(3) { width: 100pt; }
			&:nth-child(4) { width: calc(60% - 170pt); }
			&:nth-child(5) { width: calc(40% - 250pt); }
			&:nth-child(6) { width: 150pt; font-weight: bold; }
			&:nth-child(7) { width: 150pt; font-weight: bold; }
			> img {
				display: block;
				width: 40pt;
				height: 30pt;
				background-color: var(--color-shadow-hover);
				object-fit: cover;
			}
			> .vehicle---sold {
				color: var(--color-success);
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}













/*
			<div className="cloudDocs-status---container">
				<div>
					<p style={{left:'32.5%'}}>{ this.lib___numbers.format___bytesize(this.state.cloud_stats___props['cloud_documents_space___occupied___size']) }</p>
				</div>
				<div>
					<div></div>
				</div>
				<div>
					<p>0 B</p>
					<p>{ this.lib___numbers.format___bytesize(this.state.cloud_stats___props['cloud_documents_space___total']) }</p>
				</div>
			</div>
*/



.cloudDocs-licence-space---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: calc(100% - 10pt);
	height: calc(100% - var(--topbar-optionbar-h) - 10pt);

	> div {
		> img {
			display: block;
			height: 150pt;
			width: 100%;
			object-position: center;
			object-fit: contain;
		}
		> h2 {
			text-align: center;
			height: auto;
			padding: 10pt 15%;
		}
		> .cloudDocs-status---container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: calc(100% - 100pt);
			margin: 10pt 50pt;
			border-left: 2pt solid var(--color-shadow);
			border-right: 2pt solid var(--color-shadow);
			> div {
				&:nth-child(1) {
					position: relative;
					display: block;
					height: 30pt;
					> div {
						position: absolute;
						left: 0;
						display: block;
						width: auto;
						height: 60pt;
						border-right: 2pt solid var(--color-shadow);
						transform: translateX(-100%);
						> p {
							display: block;
							width: auto;
							height: 25pt;
							line-height: 30pt;
							transform: translateX(+50%) translateY(-5pt);
							background-color: var(--color-light);
							word-break: keep-all;
							white-space: nowrap;
						}
					}
					
				}
				&:nth-child(2) {
					display: flex;
					justify-content: flex-start;
					height: 30pt;
					width: 100%;
					background-color: var(--color-shadow-hover);
					> div {
						display: block;
						height: 100%;
						max-width: 100%;
						width: 0;
						min-width: 0%;
						background-color: var(--color-primary);
					}
				}
				&:nth-child(3) {
					display: flex;
					justify-content: space-between;
					padding-top: 10pt;
					> p {
						display: block;
						height: 30pt;
						background-color: var(--color-light);
						padding-top: 10pt;
						word-break: keep-all;
						white-space: nowrap;
						&:nth-child(1) {
							transform: translateY(20pt) translateX(-50%);
						}
						&:nth-child(2) {
							transform: translateY(20pt) translateX(+50%);
						}
					}
				}
			}
		}
	}
}






//													* { outline: 1px solid orange !important ; }