.edit-leads---container {
	> section {
		> div {
			&:nth-child(1) {
				display: block;
				width: 35%;
				height: 100%;
				overflow: scroll;
			}
			&:nth-child(2) {
				display: flex;
				justify-content: space-between;
				gap: 10pt;
				flex-direction: column;
				width: 65%;
				height: 100%;
				> div {
					&:nth-child(1) {
						display: block;
						width: 100%;
						height: 100%;
						overflow: scroll;
					}
					&:nth-child(2) {
					
						

					}
				}
			}
		}
	}
}

.table-lead-new---interests {
	> div {
		> div {
			&:nth-child(1) {
				width: 200pt;
				text-align: center;
				font-weight: bold;
				text-transform: uppercase;
			}
			&:nth-child(2),
			&:nth-child(4) {
				display: flex;
				width: calc((100% - 200pt) / 2);
				font-weight: bold;
				text-transform: uppercase;
				padding: 0;
				> input {
					display: block;
					width: 100%;
					height: 30pt;
					line-height: 30pt;
					border: none;
					border-bottom: 1pt solid var(--color-dark);
					height: 30pt;
					background-color: transparent;
					text-align: center;
				}
				> p {
					display: block;
					width: 30pt;
					height: 30pt;
					line-height: 30pt;
					border: none;
					border-bottom: 1pt solid var(--color-dark);
					text-align: center;
				}
			}
			&:nth-child(3) {
				width: 10pt;
			}
		}
	}
}

.brand-model---results {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 10pt;
	width: 100%;
	height: auto;
	padding-top: 10pt;
	> div {
		display: block;
		padding: 5pt 10pt;
		border: 1pt solid var(--color-shadow);
		font-size: 10pt;
		&:hover {
			background-color: var(--color-shadow-hover);
			cursor: pointer;
		}
		> span {

		}
		> i {
			&:nth-child(1) {
				padding-right: 5pt;
			}
			&:last-child {
				padding-left: 5pt;
			}
		}
	}
}

.vehicles-all---results {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}