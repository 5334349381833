.vehicle-view-single-container---top-bar {
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 10pt);
	height: 50pt;
	margin-right: 10pt;
	margin-bottom: 10pt;
	padding-left: 10pt;
	gap: 10pt;
	> img {
		display: block;
		height: 50pt;
		width: calc(150pt + 10pt + 5pt);
		object-fit: cover;
		margin: -10pt 0 -10pt -10pt;
	}
	> span {
		display: flex;
		justify-content: space-between;
		width: calc(100% - 150pt - 10pt);
		height: 30pt;
		line-height: 30pt;
		padding-right: 5pt;
		padding-left: 5pt;
		font-weight: bold;
		> span {
			&:nth-child(1) {
				font-size: 16pt;
			}
			&:nth-child(2) {
				font-size: 12pt;
				color: var(--color-primary);
			}
		}
	}
}

.page---top-bar---vehicle---is-sold {
	display: flex;
	justify-content: space-between;
	height: var(--topbar-optionbar-h);
	background-color: var(--color-primary);
	color: var(--color-light);
	margin-bottom: 10pt;
	margin-right: 10pt;
	> section {
		display: block;
		width: auto;
		height: var(--topbar-optionbar-h);
		line-height: var(--topbar-optionbar-h);
		padding: 0 10pt;
		&:nth-child(1) {
			letter-spacing: 0.1pt;
			> span {
				text-transform: uppercase;
				font-weight: bold;
			}
		}
		&:nth-child(2) {
			> a {
				cursor: pointer;
				font-weight: bold;
				> span {
					padding-right: 10pt;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			> p {
				text-transform: uppercase;
				font-weight: bold;
			}
		}
	}
}

.vehicle-overview---flexy {
	display: flex;
	justify-content: space-between;
	gap: 0pt;
	height: calc(100% - 50pt - 10pt);
	margin-right: 10pt;
	> div {
		&:nth-child(1) {
			display: flex;
			width: 175pt;
			height: 100%;
		}
		&:nth-child(2) {
			display: block;
			overflow: scroll;
			width: calc(100% - 175pt);
			height: 100%;
		}
	}
}

.vehicle-gallery---container {
	display: block;
	overflow: scroll;
	height: 100%;
}

.table-vehicle---registry {
	> div {
		> div {
			&:nth-child(1) { width: 50pt; }
			&:nth-child(2) { width: 200pt; }
			&:nth-child(3) {
				width: calc(100% - 200pt - 50pt);
				font-weight: bold;
				text-transform: uppercase;
				user-select: text;
			}
		}
	}
}

.table-vehicle-single---leads {
	> div {
		> div {
			&:nth-child(1) { width: 120pt; }
			&:nth-child(2) { width: 170pt; }
			&:nth-child(3) { width: calc(85% - 120pt - 160pt); }
			&:nth-child(4) { width: calc(15%); }
		}
	}
}