.contracts-overview---flexy {
	display: flex;
	justify-content: space-between;
	gap: 0pt;
	height: 100% !important;
	> div {
		&:nth-child(1) {
			display: flex;
			width: 275pt;
			height: 100%;
		}
		&:nth-child(2) {
			display: block;
			overflow: scroll;
			width: calc(100% - 275pt);
			height: 100%;
		}
	}
}

.contracts-overview---editor {
	display: block;
	overflow: scroll;
	margin-right: 10pt;
	height: 100%;
	> h3 {
		height: 50pt;
		line-height: 50pt;
		font-size: 14pt;
		margin-bottom: 10pt;
		font-weight: bold;
	}
	> .contracts-overview---editor---buttons {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 10pt;
		> section {
			display: flex;
			gap: 10pt;
			> button {
				display: block;
				min-width: 30pt;
				width: auto;
				height: 30pt;
				line-height: 30pt;
				transition: 0.15s;
				> span {
					display: block;
					padding: 0 10pt;
				}
				&:hover {
					opacity: 0.5;
				}
			}
			> span {
				display: block;
				width: 10pt;
			}
		}
	}
	> textarea {
		display: block;
		width: 100%;
		height: calc(100% - 50pt - 10pt - 55pt - 10pt - 30pt);
		background: url('https://carshub-manager.vercel.app/assets/ui/images/textarea-row.png');
		background-attachment: local;
		background-repeat: no-repeat;
		padding-left: 35px;
		padding-top: 10px;
		border-color:#ccc;
	}
}

.contracts-overview---help {
	display: block;
	padding: 50pt;
	> h2 {
		font-weight: bold;
		font-size: 12pt;
	}
	> div {
		display: flex;
		justify-content: space-between;
		gap: 10pt;
		padding: 10pt;
		> i {
			display: block;
			width: 40pt;
			font-size: 10pt;
		}
		> p {
			display: block;
			width: calc(100% - 40pt - 10pt);
			font-size: 10pt;
		}
	}
	> p {
		display: block;
		width: 100%;
		opacity: 0.5;
		font-size: 10pt;
	}
}