//#region 																							I M P O R T S

	@import 'bulma/css/bulma.css';
	@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');
	@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//#endregion

//#region 																							D E C L A R A T I O N S
		
	:root {

		--topbar-h: 40pt;
		--topbar-optionbar-h: 35pt;

		--bottombar-h: 20pt;
		--bottombar-buttons-h: 35pt;

		--card-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.15);

		/** variables **/

		--color-logo: #29B6F6;

//		--color-primary: #ffad1f;
		--color-primary: #01579B;

		--color-light: #FFFFFF;
		--color-shadow: #D6D6D6;
		--color-shadow-hover: #f3f3f3;
		--color-success: #009688;
		--color-warning: #FFA31A;
		--color-danger: #F44336;
		--color-blacky: #4D4D4D;
		--color-dark: #0D0D0D;

		/** primary **/

		--ion-color-primary: var(--color-primary);
		--ion-color-primary-rgb: var(--color-primary);
		--ion-color-primary-contrast: var(--color-light);
		--ion-color-primary-contrast-rgb: var(--color-light);
		--ion-color-primary-shade: var(--color-primary);
		--ion-color-primary-tint: var(--color-primary);

		/** danger **/

		--ion-color-danger: var(--color-primary);
		--ion-color-danger-rgb: var(--color-primary);
		--ion-color-danger-contrast: var(--color-light);
		--ion-color-danger-contrast-rgb: var(--color-light);
		--ion-color-danger-shade: var(--color-primary);
		--ion-color-danger-tint: var(--color-primary);

	}

//#endregion

//#region 																							E L E M E N T S

	* {
		font-family: 'Noto Sans', sans-serif;
//		font-size: 99%;
//		outline: 1px solid orange !important;
	}

	*::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 0;
		height: 0;
	}

	*:not(input):not(textarea) { user-select: none; }

	a { color: inherit !important; }

	.is-card {
		position: relative;
		display: block;
		box-shadow: var(--card-shadow);
		padding: 10pt;
		background-color: var(--color-light);
		height: auto;
		.is-title {
			z-index: 2;
			position: sticky;
			top: -10pt;
			display: block;
			width: 100%;
			height: 40pt;
			line-height: 25pt;
			font-size: 15pt;
			padding: 10pt 0;
			font-weight: bold;
			background-color: var(--color-light);
			&.has-icon {
				display: flex;
				justify-content: space-between;
				> span {
					display: block;
					width: auto;
					height: 25pt;
					line-height: 25pt;
				}
				> i {
					display: block;
					width: auto;
					height: 25pt;
					line-height: 25pt;
				}
			}
		}
		> .is-content {
			padding-top: 10pt;
			height: auto;
		}
		> .is-note {
			transform: scale(0.75);
			opacity: 0.5;
		}
		&.is-button {
			cursor: pointer;
			&.is-submit {
				background-color: var(--color-success);
				color: var(--color-light);
			}
			&.is-standard {
				background-color: var(--color-shadow-hover);
				color: var(--color-blacky);
			}
			&.is-cancel {
				background-color: var(--color-danger);
				color: var(--color-light);
			}
			&.is-disabled {
				cursor: default !important;
				* {
					opacity: 0.5;
				}
			}
			&.is-hovered, 
			&:hover {
				opacity: 0.75;
			}
			> i {
				margin-right: 10pt;
			}
			> span {
				font-weight: bold;
				text-transform: uppercase;
			}
			&:hover {
				background-color: var(--color-shadow-hover);
			}
		}
	}
	
	.no-shadow {
		box-shadow: none !important;
	}

	.page---container {
		position: fixed;
		top: var(--topbar-h) !important;
		display: flex;
		justify-content: center;
		width: 100vw;
		height: calc(100vh - var(--topbar-h) - var(--bottombar-h));
		overflow-x: hidden;
		scroll-behavior: smooth;
		> section {
			position: relative;
			display: block;
			width: 100%;
			max-width: 624pt;
			padding: 10pt;
		}
		&.is-full-width {
			> section {
				max-width: 100% !important;
				padding: 10pt 0 10pt 10pt !important;
			}
			&.is-single-section {
				> section {
					padding: 10pt !important;
					> div {
						&:nth-child(2) {
							height: calc(100% - var(--topbar-optionbar-h) - 10pt);
							> div {
								height: 100%;
							}
						}
					}
				}
			}
			&.is-flexy {
				> section {
					display: flex;
					gap: 10pt;
					> div {
						&:nth-child(1) {
							width: 350pt;
						}
						&:nth-child(2) {
							display: flex;
							justify-content: space-between;
							gap: 10pt;
							flex-direction: column;
							width: calc(100% - 350pt);
							height: 100%;
							padding-right: 10pt;
							> div {
								&:nth-child(1) {
									display: block;
									width: 100%;
									height: var(--topbar-optionbar-h);
									margin-bottom: 0;
								}
								&:nth-child(2) {
									display: block;
									width: calc(100%);
									height: calc(100% - var(--topbar-optionbar-h) - 10pt);
									overflow-x: visible;
									overflow-y: scroll;
								}
							}
						}
					}
				}
			}
			&.is-scrolly {
				> section {
					> div {
						&:nth-child(2) {
							display: block;
							width: 100%;
							height: calc(100% - var(--topbar-optionbar-h) - 10pt);
							overflow-x: visible;
							overflow-y: scroll;
						}
					}
				}
			}
		}
	}

	.page---top-bar {
		position: sticky;
		z-index: 10;
		top: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: var(--topbar-optionbar-h);
		padding: 0 10pt 10pt 0;
		margin-bottom: 10pt;
		background-color: var(--color-light);
		> section {
			display: flex;
			justify-content: flex-start;
			width: auto;
			height: 25pt;
			gap: 10pt;
			> input {
				display: none;
				&:checked {
					+ label {
						border-color: var(--color-primary);
						&:hover {
							background-color: var(--color-light) !important;
							cursor: default !important;
						}
					}
				}
			}
			> a,
			  button,
			  div,
			  label {
				display: flex;
				gap: 10pt;
				height: var(--topbar-optionbar-h);
				width: auto;
				border-bottom: 2.5pt solid transparent;
				cursor: pointer;
				border-radius: 0 !important;
				text-overflow: ellipsis;
				overflow: hidden;
				> i,
				  img {
					height: 15pt;
					width: 15pt;
					display: block;
					&.is-rotating {
						animation-name: page-container---bottom-bar---buttons---button---i---is-rotating;
						animation-duration: 1s;
						animation-iteration-count: infinite;
						animation-direction: normal;
					}
				}
				> i,
				  span {
					display: block;
					height: 15pt;
					line-height: 15pt;
					font-size: 10pt;
					text-transform: uppercase;
					letter-spacing: 1;
					font-weight: bold;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				&.is-checked {
					border-color: var(--color-primary);
				}
				&.is-reset {
					background-color: var(--color-danger) !important;
					color: var(--color-light) !important;
				}
				&.is-disabled {
					cursor: default !important;
					opacity: 0.5;
				}
				&:not(.is-checked):not(.is-disabled):hover {
					background-color: var(--color-shadow-hover);
				}
			}
			> span {
				display: block;
				width: 40pt;
			}
			> select {
				display: flex;
				gap: 10pt;
				height: 25pt;
				width: auto;
				border-bottom: 2.5pt solid transparent;
				border: none;
				cursor: pointer;
			}
			> .page---top-bar---select-box,
			> .page---top-bar---search-box {
				display: flex;
				gap: 10pt;
				padding: 5pt 10pt;
				background-color: var(--color-light) !important;
				> i,
				> p {
					display: block;
					height: 25pt;
					line-height: 25pt;
				}
				> input,
				> select {
					display: block;
					width: 150pt;
					height: 25pt;
					line-height: 25pt;
					border: none;
					border-bottom: 1pt solid var(--card-shadow);
				}
				> select {
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
		&.is-scrollable {
			overflow-x: scroll;
		}
		&.page---top-bar---is-sidebar {
			position: relative;
			z-index: unset;
			width: 100% !important;
			height: auto !important; 
			flex-direction: column;
			padding-bottom: 0;
			margin-bottom: 0;
			> section {
				width: 100% !important;
				height: auto !important;
				flex-direction: column !important;
				justify-content: flex-start !important;
			}
		}
		&.is-backgrounded {
			top: -10pt !important;
			width: calc(100% + 10pt) !important;
			padding: 10pt 10pt 0 10pt !important;
			margin: -10pt -10pt 10pt -10pt !important;
			background-color: var(--color-light) !important;
			height: 50pt !important;
		}
	}

	@keyframes page-container---bottom-bar---buttons---button---i---is-rotating {
		from { transform: rotate(0deg);} to { transform: rotate(360deg); }
	}
	
	.page-container---bottom-bar---buttons {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		height: var(--bottombar-buttons-h);
		padding: 0;
		gap: 10pt;
		> section {
			display: flex;
			gap: 10pt;
		}
		&.is-spaced {
			justify-content: space-between;
		}
		a,
		button {
			display: flex;
			width: auto;
			height: var(--bottombar-buttons-h);;
			border-bottom: 2.5pt solid transparent;
			cursor: pointer;
			border-radius: 0 !important;
			background-color: var(--color-shadow-hover);
			color: var(--color-blacky);
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 10pt;
			text-align: center;
			> i,
			  img {
				display: block;
				height: 15pt;
				line-height: 15pt;
				width: 40pt;
			}
			> span {
				display: block;
				height: 15pt;
				line-height: 15pt;
				text-transform: uppercase;
				letter-spacing: 1;
				font-weight: bold;
				width: 0;
				opacity: 0;
				transition: 0.15s;
				overflow: hidden;
				text-overflow: ellipsis;
				overflow: hidden;
				&.is-full-width {
					width: auto !important;
					margin-right: 0 !important;
					padding-left: 10pt !important;
					padding-right: 10pt !important;
					opacity: 1 !important;
					text-align: left !important;
				}
			}
			&.is-primary {
				background-color: var(--color-primary) !important;
				color: var(--color-light) !important;
			}
			&.is-submit {
				background-color: var(--color-success) !important;
				color: var(--color-light) !important;
			}
			&.is-cancel {
				background-color: var(--color-danger) !important;
				color: var(--color-light) !important;
			}
			&.is-disabled {
				cursor: default !important;
				* {
					opacity: 0.5;
				}
			}
			&.is-hovered, 
			&:hover {
				opacity: 0.75;
				> span {
					margin-right: 10pt;
					padding-left: 10pt;
					padding-right: 10pt;
					opacity: 1;
					text-align: left;
					width: auto;
				}
			}
		}
		&.is-centered {
			justify-content: center !important;
			gap: 15pt;
		}
		&.is-reversed {
			justify-content: flex-start !important;
		}
	}

//	* { outline: 1px solid orange !important; }

	.container---table {
		display: block;
		height: auto;
		&.is-card {
			> div {
				&:nth-child(1) {
					top: -10pt !important;
				}
			}
		}
		> div {
			display: flex;
			justify-content: flex-start;
			border-bottom: 1pt solid var(--color-shadow);
			vertical-align: middle !important;
			align-items: center;
			&:nth-child(1) {
				position: sticky;
				top: 0;
				z-index: 123;
				background-color: var(--color-light);
				> div {
					display: flex;
					justify-content: space-between;
					padding: 10pt;
					font-weight: bold;
					align-items: center;
					> span {
						line-height: 20pt;
						&:not(:nth-child(1)) {
							opacity: 0.5;
						}
					}
					> i { 
						line-height: 20pt;
						opacity: 0.5;
						cursor: pointer;
					}
				}
			}
			&:not(:nth-child(1)) {
				> div {
					line-height: 20pt;
					font-size: 10pt;
					padding: 5pt 10pt;
					align-items: center;
					&:nth-child(1) {
						display: flex;
						justify-content: center;
						gap: 15pt;
						i {
							display: block;
							width: 12pt;
							height: 20pt;
							line-height: 20pt;
							cursor: pointer;
							opacity: 0.5;
							transform: scale(0.95);
							transition: 0.15s;
							text-align: center;
							&:hover {
								opacity: 1;
								transform: scale(1);
							}
						}
						> span {
							display: block;
							width: 12pt;
							height: 20pt;
							line-height: 20pt;
							transform: scale(0.95);
						}
					}
					&:not(:nth-child(1)) {
						> a {
							text-decoration: underline;
							font-weight: bold;
							font-style: italic;
						}
					}
				}
				&:not(.container---table---spacer):hover {
					background-color: var(--color-shadow-hover);
				}
			}
			&.container---table---spacer {
				height: 10pt;
			}
		}
	}




























//#endregion

//#region 																							I N P U T S

	select {
		&:not(:disabled) {
			cursor: pointer;
		}
	}

	.input---nb-title {
		display: block;
		width: 100%;
		height: 10pt;
		line-height: 10pt;
		font-size: 14pt;
		font-weight: bold;
		text-transform: lowercase;
		margin-bottom: 5pt;
		letter-spacing: 0.25pt;
		margin: 20pt 0;
		z-index: 1;
	}

	.input---nb-label {
		display: block;
		width: 100%;
		height: 10pt;
		line-height: 10pt;
		font-size: 10pt;
		font-weight: bold;
		opacity: 0.5;
		text-transform: lowercase;
		margin-bottom: 5pt;
		letter-spacing: 0.25pt;
		margin: 10pt 0;
		z-index: 1;
		> a {
			text-decoration: underline;
		}
		&.is-required {
			&::after {
				content: 'richiesto';
				color: var(--color-danger);
				padding-left: 10pt;
			}
		}
	}

	.input---nb-outlined {
		display: block;
		width: 100%;
		height: 30pt;
		min-height: 30pt;
		max-height: 30pt;
		border-radius: 0;
		background-color: unset;
		border: 1pt solid var(--color-shadow);
		resize: none;
		padding: 0 10pt;
		background-color: var(--color-light);
		&.is-textarea {
			padding: 10pt 10pt;
			height: 250pt;
			max-height: unset;
			resize: none !important;
		}
	}

	.input---nb-outlined---file {
		display: block;
		width: 100%;
		height: 30pt;
		line-height: 30pt;
		min-height: 30pt;
		max-height: 30pt;
		border-radius: 0;
		background-color: unset;
		border: 1pt solid var(--color-shadow);
		resize: none;
		padding: 0 10pt;
		background-color: var(--color-light);
		* {
			height: 30pt;
			line-height: 30pt;
		}
		> label {
			display: flex;
			justify-content: space-between;
			width: 100%;
			cursor: pointer !important;
			> input {
				display: none;
			}
		}
	}

	.input---nb-outlined---flexy {
		display: flex;
		justify-content: space-between;
		gap: 15pt;
		> section {
			display: flex;
			width: 100%;
		}
		i, p {
			display: block;
			width: auto;
			height: 30pt;
			line-height: 30pt;
			min-height: 30pt;
			max-height: 30pt;
			padding: 0 15pt 0 15pt;
			margin: 0;
			&:not(:nth-of-type(1))
			&:not(:last-of-type) {
				padding: 0 !important;
			}
			&.fa-times {
				cursor: pointer;
				&:hover {
					color: var(--color-danger);
				}
			}
		}
		*:not(section):not(p):not(span):not(i):not(div):not(i):not(button) {
			display: block;
			width: 100%;
			height: 30pt;
			min-height: 30pt;
			max-height: 30pt;
			border-radius: 0;
			background-color: unset;
			border: 1pt solid var(--color-shadow);
			resize: none;
			padding: 0 10pt;
			background-color: var(--color-light);
			&.is-textarea {
				padding: 10pt 10pt;
				height: 250pt;
				max-height: unset;
				resize: none !important;
			}
		}
		label {
			> input {
				display: none;
			}
		}
		+ .input---nb-description {
			padding-left: 15pt !important;
		}
		&.has-no-input {
			> div {
				display: block;
				width: 100%;
				height: 30pt;
				line-height: 30pt;
				min-height: 30pt;
				max-height: 30pt;
				border-radius: 0;
				background-color: unset;
				border: 1pt solid var(--color-shadow);
				resize: none;
				padding: 0 10pt;
				background-color: var(--color-light);
			}
		}
		&.is-selectable {
			> * {
				&:nth-child(1) {
					width: calc(100% - 200pt - 10pt);
				}
				&:nth-child(2) {
					width: 200pt;
				}
			}
		}
	}

	.input---nb-outlined---cb {
		display: block;
		gap: 10pt;
		> p {
			display: block;
			width: auto;
			height: 30pt;
			line-height: 30pt;
			min-height: 30pt;
			padding: 0 15pt 0 15pt;
			margin: 0;
		}
		> label {
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			> input {
				display: block;
				width: 12.5pt;
				height: 12.5pt;
				line-height: 30pt;
				min-height: 30pt;
				margin: 0 15pt 0 15pt;
				text-align: center;
				color: var(--color-primary);
				&:checked + p {
					font-weight: bold;
				}
			}
			> p {
				display: block;
				width: calc(100% - 10pt - 30pt);
				height: 30pt;
				line-height: 30pt;
				min-height: 30pt;
				padding: 0 15pt 0 15pt;
				margin: 0;
			}
		}
	}

	.input---nb-outlined---flexy---cb {
		display: flex;
		justify-content: 'flex-start';
		gap: 10pt;
		> p {
			display: block;
			width: auto;
			height: 30pt;
			line-height: 30pt;
			min-height: 30pt;
			padding: 0 15pt 0 0;
			margin: 0;
		}
		> label {
			display: flex;
			justify-content: space-between;
			border: 1pt solid var(--color-shadow);
			cursor: pointer;
			width: 20%;
			padding: 0 15pt;
			> input {
				display: block;
				width: 12.5pt;
				height: 12.5pt;
				line-height: 30pt;
				min-height: 30pt;
				margin: 0 15pt 0 15pt;
				text-align: center;
				color: var(--color-primary);
				&:checked + p {
					font-weight: bold;
				}
			}
			> p {
				display: block;
				width: calc(100% - 10pt - 30pt);
				height: 30pt;
				width: 100%;
				line-height: 30pt;
				min-height: 30pt;
				padding: 0 15pt 0 15pt;
				margin: 0;
				text-align: center;
			}
		}
	}

	.input---nb-description {
		display: block;
		width: 100%;
		min-height: 10pt;
		line-height: 10pt;
		font-size: 10pt;
		opacity: 0.5;
		text-transform: lowercase;
		margin-top: 5pt;
		letter-spacing: 0.25pt;
	}

	.input---nb-dropdown {
		position: relative;
		> div {
			&:nth-child(1) {
				display: flex;
				justify-content: space-between;
				padding: 10pt;
				width: 100%;
				border: 1pt solid var(--color-shadow);
				> span {
					display: block;
					height: 20pt;
					line-height: 20pt;
					font-size: 12pt;
					text-transform: uppercase;
					font-weight: bold;
				}
				> i {
					display: block;
					height: 20pt;
					line-height: 20pt;
				}
				&:hover  {
					background-color: var(--color-shadow-hover);
					& + div:nth-child(2) {
						display: block;
					}
				}
			}
			&:nth-child(2) {
				position: absolute;
				width: 100%;
				top: 0;
				display: none;
				z-index: 2;
				> span {
					display: block;
					width: 100%;
					height: 35pt;
					line-height: 25pt;
					padding: 5pt 10pt;
					font-size: 10pt;
					text-align: center;
					cursor: pointer;
					> span {
						text-transform: uppercase;
						font-weight: bold;
					}
					&:hover {
						background-color: var(--color-shadow-hover);
					}
				}
				&:hover {
					display: block;
				}
			}
		}
	}

	.input---nb-richtext {
		display: block;
		text-align: justify;
		text-justify: inter-word;
		font-size: 12pt;
		line-height: 25pt;
		padding: 15pt;
		> b {
			display: inline;
			border: none;
			border-bottom: 1pt solid var(--color-dark);
			width: auto !important;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;
			padding: 0 10pt;
			margin: 0 10pt;
			min-width: 50pt;
		}
	}

	textarea {
		height: auto;
		resize: none !important;
		background-color: var(--color-light);
	}

	input,
	textarea,
	select {
		position: relative;
	}

	input:focus,
	input:active,
	textarea:focus,
	textarea:active,
	select:focus,
	select:active {
		outline: none !important;
	}

	button {
		display: block;
		min-height: 30pt;
		min-width: auto;
		border: none;
		font-weight: bold;
		cursor: pointer;
	}







//#endregion
































//								* 										{ outline: 1pt solid orange !important; }
