.carshub-sign---container {
	display: flex;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 300pt;
		> img {
			display: block;
			width: 100%;
			height: 150pt;
			transform: scale(0.75);
			object-fit: contain;
		}
		> input {
			width: 100%;
			margin-bottom: 10pt;
			text-align: center;
		}
		> p {
			display: flex;
			padding: 0 10pt;
			> label {
				cursor: pointer;
				> input {
					display: none;
				}
				> i {
					display: block;
					font-size: 14pt;
					line-height: 16pt;
					&.fa-check-square {
						color: var(--color-primary);
					}
					&.fa-square {
						color: var(--color-danger);
					}
				}
			}
			> span {
				display: block;
				width: 100%;
				margin: 0;
				font-size: 10pt;
				line-height: 16pt;
				text-align: justify;
				padding-left: 15pt;
				> span {
					font-weight: bold;
					color: var(--color-folly);
					&:hover { text-decoration: underline !important; }
					cursor: pointer;
				}
			}
		}
		> div {
			justify-content: center;
		}
	}
}