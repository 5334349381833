.dealer---overview---container {
	display: flex;
	justify-content: center;
	padding: 20pt calc((100% - 800pt) / 2);
	gap: 50pt;
	height: auto;
	> div {
		display: block;
		height: auto;
		&:nth-child(1) {
			width: calc(100% - 70% - 50pt);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			gap: 75pt;
			padding-top: 10pt;
			> section {
				> span {
					margin-bottom: 25pt;
				}
			}
		}
		&:nth-child(2) {
			width: calc(100% - 30%);
			padding-left: 50pt;
			border-left: 1pt solid var(--color-shadow);
			overflow-y: scroll;
		}
	}
}

.dealer---connector---container {
	display: flex;
	justify-content: center;
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 800pt;
		> img {
			transform: scale(0.5);
		}
	}
}

.dealer---api---container {
	display: block;
	> section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 800pt;
		> img {
			display: block;
			height: 50pt;
			width: auto;
			margin-bottom: 10pt;
		}
	}
}