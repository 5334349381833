.standalone-download---container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: calc(100% - 10pt);
	> .standalone-download---container---logo {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100pt;
		> img {
			display: block;
			height: 100%;
			width: 500pt;
			object-position: center;
			object-fit: contain;
		}
	}
	> h2,
	> span {
		text-align: center;
		height: auto;
		padding: 10pt 15%;
	}
	> .standalone-download---container---downloads {
		display: flex;
		justify-content: center;
		gap: 75pt;
		> a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10pt;
			width: 200pt;
			height: auto;
			padding: 20pt 15pt;
			transition: 0.15s;
			> img {
				display: block;
				width: 100%;
				height: 100pt;
			}
			> p {
				display: block;
				width: 100%;
				text-align: center;
				font-size: 14pt;
				font-weight: bold;
			}
			> span {
				display: block;
				width: 100%;
				text-align: center;
				font-weight: bold;
				font-size: 10pt;
				opacity: 0.5;
			}
			> i {
				display: block;
				width: 100%;
				text-align: center;
			}
			&:hover {
				background-color: var(--color-shadow-hover);
			//	opacity: 0.5;
				> i {
					color: var(--color-primary);
				}
			}
		}
	}
}



//																		* { outline: 1px solid orange !important; }