.vehicle-editor---top-bar {
	> section {
		> div {
			cursor: pointer !important;
			transition: 0.15s;
			&.is-selected-item {
				cursor: default !important;
				border-bottom: 2pt solid var(--color-primary);
			}
		}
	}
}

.vehicle-editor---container---flexy {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	overflow: hidden;
	top: calc(var(--topbar-h) + 5pt);
	right: 0;
	bottom: calc(var(--bottombar-h) + 10pt);
	left: 0;
	padding: 10pt;
	width: 100%;
	> div {
		&:nth-child(1) {
			display: block;
			width: 175pt !important;
			height: 100%;
		}
	}
}

//														* { outline: 1px solid orange !important; }

.vehicle-editor---container {
	position: fixed;
	top: 0;
	right: 15pt;
	bottom: 0;
	left: calc(175pt + 15pt);
	overflow: none;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 0;
	height: calc(100vh * 10) !important;
	transition: 0.15s;
	> div {
		display: block;
		overflow: scroll;
		width: 100%;
		padding: 0 calc(((100% - 724pt) / 2));
		height: calc(100vh - var(--topbar-h) - 15pt - var(--bottombar-h) - 10pt);
		margin-top: calc(var(--topbar-h) + 15pt);
		margin-bottom: calc(var(--bottombar-h) + 10pt) !important;
		> .vehicle-editor---title {
			z-index: 199;
			position: sticky;
			display: flex;
			justify-content: flex-start;
			width: 100%;
			background-color: var(--color-light);
			> img {
				display: block;
				width: 100pt;
				height: 70pt;
				object-fit: contain;
				transform: scale(0.65);
			}
			> span {
				display: block;
				width: calc(100% - 100pt);
				height: 70pt;
				line-height: 70pt;
				font-size: 14pt;
			}
		}
		> .vehicle-editor---section {
			display: block;
			width: calc(100% - 10pt - 100pt - 100pt);
			margin: 10pt 10pt 10pt 100pt !important;
			padding-bottom: 25pt;
		}
	}
}

//			c o m p o n e n t s

.vehicle-editor---360pic {
	display: block;
	width: 100%;
	height: 250pt;
	border-radius: 6pt;
	overflow: hidden;
}

.efficiency---car-body {
	display: flex;
	gap: 10pt;
	> div {
		&:nth-child(1) {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 30%;
			> img {
				position: sticky;
				top: 100pt;
			}
		}
		&:nth-child(2) {
			width: 70%;
		}
	}
}

.vehicle-edit---is-submitted---container {
	display: block;
	margin-right: 10pt;
	height: 100%;
	> section {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		> img {
			display: block;
			width: 100%;
			height: 50pt;
		}
		> h3 {
			display: block;
			margin: 25pt 200pt;
			width: calc(100% - 50%);
			font-weight: bold;
			font-size: 16pt;
			text-align: center;
			text-transform: lowercase;
		}
	}
}