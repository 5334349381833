.com-lead---container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10pt;
	width: 100%;
	overflow: hidden;
	> section {
		z-index: 2;
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 10pt;
		&:nth-child(1) {
			> .com-lead---upperstatus {
				display: block;
				height: 40pt;
				padding: 10pt;
				line-height: 20pt;
				font-size: 12pt;
				width: 100%;
				border: 1pt solid var(--color-shadow);
				font-weight: bold;
				text-transform: uppercase;
				background-color: var(--color-light);
				&.lead---is-new {
					border-color: var(--color-success);
					background-color: var(--color-success);
					color: var(--color-light);
				}
				&.lead---is-sold {
					border-color: var(--color-primary);
					background-color: var(--color-primary);
					color: var(--color-light);
				}
				&.lead---is-archived {
					border-color: var(--color-warning);
					background-color: var(--color-warning);
					color: var(--color-light);
				}
			}
			> .com-lead---options---dropdown {
				position: relative;
				background-color: var(--color-light);
				> div {
					&:nth-child(1) {
						display: flex;
						justify-content: space-between;
						padding: 10pt;
						width: 100%;
						border: 1pt solid var(--color-shadow);
						> span {
							display: block;
							height: 20pt;
							line-height: 20pt;
							font-size: 12pt;
							text-transform: uppercase;
							font-weight: bold;
						}
						> i {
							display: block;
							height: 20pt;
							line-height: 20pt;
						}
						&:hover  {
							background-color: var(--color-shadow-hover);
							& + div:nth-child(2) {
								display: block;
							}
						}
					}
					&:nth-child(2) {
						position: absolute;
						z-index: 12;
						width: 100%;
						top: 0;
						display: none;
						> span {
							display: block;
							width: 100%;
							height: 35pt;
							line-height: 25pt;
							padding: 5pt 10pt;
							font-size: 10pt;
							text-align: center;
							cursor: pointer;
							> span {
								text-transform: uppercase;
								font-weight: bold;
							}
							&:hover {
								background-color: var(--color-shadow-hover);
							}
						}
						&:hover {
							display: block;
						}
					}
				}
			}
			> .com-lead---image {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				height: 120pt;
				margin-bottom: 10pt;
				> img {
					display: block;
					width: 100%;
					height: 120pt;
					object-fit: cover;
					&:not(:nth-of-type(1)) {
						display: none !important;
					}
					&.img-car---is-not-available {
						filter: grayscale(0.75);
						opacity: 0.5;
					}
				}
				> section {
					display: flex;
					justify-content: center;
					vertical-align: middle;
					align-items: stretch;
					align-content: center;
					flex-wrap: wrap;
					width: 100%;
					height: 100%;
					> img {
						display: block;
						width: 70pt;
						height: 70pt;
						object-fit: contain;
					}
				}
				> span {
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					display: block;
					background-color: var(--color-danger);
					color: var(--color-light);
					text-align: center;
					font-weight: bold;
					text-transform: uppercase;
					font-size: 12pt;
					height: 30pt;
					line-height: 30pt;
					border-top: 1pt solid var(--color-light);
				}
			}
			> h3 {
				display: block;
				font-size: 10pt;
				font-weight: bold;
				opacity: 0.5;
				font-size: 10pt;
				height: 10pt;
				line-height: 10pt;
				margin: 0;
				text-transform: uppercase;
			}
			> h2 {
				display: block;
				font-weight: bold;
				font-size: 12pt;
				text-transform: uppercase;
				height: 15pt;
				line-height: 15pt;
				margin-bottom: 5pt;
			}
			> h4 {
				display: flex;
				flex-wrap: wrap;
				font-weight: bold;
				gap: 5pt;
				font-size: 10pt;
				margin-bottom: 5pt;
				> a,
				> span {
					position: relative;
					display: inline;
					height: 25pt;
					line-height: 25pt;
					padding: 0 5pt;
					border: 1pt solid var(--color-shadow);
					background-color: var(--color-shadow-hover);
					overflow: hidden;
					text-overflow: ellipsis;
					width: auto;
					max-width: 100%;
					height: 25pt;
					line-height: 25pt;
					gap: 10pt;
					> i {
						display: inline;
						width: 25pt;
						height: 25pt;
						line-height: 25pt;
						background-color: var(--color-shadow-hover);
						padding-left: 5pt;
					}
					&.lead-vehicle-chosen-component---com-warning {
						background-color: var(--color-warning);
						color: var(--color-light);
						width: 100%;
						text-transform: uppercase;
					}
				}
			}
		}
		&:nth-child(2) {
			> h5 {
				display: block;
				font-weight: bold;
				font-size: 10pt;
				height: auto;
				line-height: 15pt;
				margin: 0;
				> span {
					> span {
						padding-right: 10pt;
						&:nth-child(odd) {
							opacity: 0.5;
						}
						&:nth-child(even) {
							font-weight: bold;
							text-transform: uppercase;
						}
					}
				}
			}
			> .com-lead---action-btn {
				display: flex;
				justify-content: space-between;
				gap: 10pt;
				> button {
					display: block;
					margin-top: 10pt;
					font-weight: bold;
					width: 40pt;
					height: 40pt;
					border: 1pt solid var(--color-shadow);
					background-color: var(--color-light);
					&:hover  {
						background-color: var(--color-shadow-hover);
						& + div:nth-child(2) {
							display: block;
						}
					}
					&.com-lead---action-btn---unviewed {
						background-color: var(--color-primary);
						color: var(--color-light);
					}
				}
				> a {
					display: flex;
					justify-content: space-between;
					gap: 15pt;
					padding: 10pt;
					width: calc(100% - 40pt - 10pt);
					height: 40pt;
					border: 1pt solid var(--color-shadow);
					cursor: pointer;
					margin-top: 10pt;
					> span {
						display: block;
						height: 20pt;
						line-height: 20pt;
						font-size: 10pt;
						text-transform: uppercase;
						font-weight: bold;
					}
					> i {
						display: block;
						height: 20pt;
						line-height: 20pt;
						font-size: 10pt;
					}
					&:hover  {
						background-color: var(--color-shadow-hover);
						& + div:nth-child(2) {
							display: block;
						}
					}
				}
			}
		}
	}
}


//															* { outline: 1pt solid orange !important; }