.com-calendar-event---container {
	position: relative;
	height: auto;
	width: 100% !important;
	display: flex;
	flex-direction: row;
	gap: 15pt;
	color: var(--color-dark);
	margin-bottom: 15pt;
	cursor: pointer !important;
	padding: 10pt 0;
	border-left: 2pt solid var(--color-primary);
	padding-left: 10pt;
	transition: 0.15s;
	> div {
		&:nth-child(1) {
			display: block;
			width: 50pt;
			> h3 {
				display: block;
				font-weight: bold;
				width: 100%;
				font-size: 12pt;
				height: 14pt;
				line-height: 14pt;
				text-align: center;
			}
		}
		&:nth-child(2) {
			display: flex;
			flex-direction: column;
			gap: 10pt;
			width: calc(100% - 55pt - 55pt);
			> h4 {
				display: block;
				font-size: 12pt;
				height: 14pt;
				line-height: 14pt;
				font-weight: bold;
				text-transform: uppercase;
			}
			> p {
				font-size: 10pt;
			}
			> div {
				display: flex;
				justify-content: space-between;
				gap: 10pt;
				> img {
					display: block;
					width: 60pt;
					height: 34pt;
					object-fit: cover;
					background-color: var(--color-dark);
				}
				> p {
					display: block;
					width: calc(100% - 60pt);
					font-size: 10pt;
				}
			}
		}
		&:nth-child(3) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50pt;
			text-align: center;
			> i {
				display: block;
				width: 50pt;
				height: 50pt;
				line-height: 50pt;
			}
		}
	}
	&:after {
		position: absolute;
		z-index: 10;
		top: 0;
		bottom: 0;
		left: 0;
		content: '';
		width: 0;
		height: 100%;
		background-color: var(--color-primary);
		transition: 0.15s;
	}
	&:hover {
		background-color: var(--color-shadow-hover);
		&:after {
			width: 5pt;
		}
	}
	&.event---is-activity {									//		activity
		border-color: var(--color-blacky);
		&:after { background-color: var(--color-blacky); }
	}
	&.event---is-lead {										//		lead
		border-color: var(--color-warning);
		&:after { background-color: var(--color-warning); }
	}
	&.event---is-sale {										//		sale
		border-color: var(--color-success);
		&:after { background-color: var(--color-success); }
	}
	&.event---is-vehicle-car {								//		vehicle car
		border-color: var(--color-primary);
		&:after { background-color: var(--color-primary); }
	}
	&.event---is-vehicle-motorbike {						//		vehicle motorbike
		border-color: var(--color-primary);
		&:after { background-color: var(--color-primary); }
	}
}