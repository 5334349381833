.slideshow---container {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	> div {
		&:nth-child(1) {
			display: flex;
			justify-content: space-between;
			background-color: var(--color-dark);
			height: calc(100% - 90pt);
			> section {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				width: 40pt;
				> i {
					display: block;
					padding: 0;
					width: 40pt;
					height: 30pt;
					line-height: 30pt;
					cursor: pointer;
					text-align: center;
					color: var(--color-light);
					background-color: transparent;
					transition: 0.15s;
					&:hover {
						transform: scale(1.25);
						opacity: 0.5;
					}
				}
			}
			> img {
				display: block;
				width: calc(100% - 40pt - 40pt);
				height: 100%;
				background-color: var(--color-dark);
				object-fit: contain;
			}
		}
		&:nth-child(2) {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			padding-top: 5pt;
			height: 90pt;
			gap: 5pt;
			overflow: hidden;
			> div {
				display: block;
				width: calc((100% - 5pt * 19) / 20);
				height: 40pt;
				overflow: hidden;
				cursor: pointer;
				> img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					background-color: var(--color-dark);
					transition: 0.15s;
				}
				&:hover {
					> img {
						transform: scale(1.2);
						opacity: 0.5;
					}
				}
			}
		}
	}
}