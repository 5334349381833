.wp-tooltip-wrapper---element {
	position: fixed;
	z-index: 1000;
	width: auto;
	max-width: 300pt;
	background-color: var(--color-light);
	color: var(--color-dark);
	padding: 5pt 10pt;
	border-radius: 0;
	font-size: 8pt;
	pointer-events: none !important;
	box-shadow: var(--card-shadow);
}